import { privacyPolicyMarkdownMock } from '@/mocks/privacyPolicyMarkdown';
import { termsOfUseMarkdownMock } from '@/mocks/termsOfUseMarkdown';
import { api, TCustomAxiosPaginationResponse } from '..';
import { TLegalDocument } from '@/types/Legal.types';

const root = 'v1/legal-documents';

const endpoints = {
  legalDocuments: `${root}`,
  legalDocumentsActivate: `${root}/activate`,
  legalDocumentsDeactivate: `${root}/deactivate`,
};

export const getAllLegalDocuments = async (page: number): Promise<TCustomAxiosPaginationResponse<TLegalDocument[]>> => {
  const { data } = await api.get(endpoints.legalDocuments, {
    params: { page },
  });

  return data;
};

export const postCreateLegalDocument = async ({ type }: { type: string }): Promise<TLegalDocument> => {
  const formattedType = type.toLowerCase().replaceAll('_', '-');

  const templateContent = formattedType === 'privacy-policy' ? privacyPolicyMarkdownMock : termsOfUseMarkdownMock;

  const emptyMarkdownContent = `# ${type} \n\n${templateContent}`;

  const { data } = await api.post(endpoints.legalDocuments, {
    type,
    content: emptyMarkdownContent,
    isActive: false,
  });

  const newDocument = data.data;

  return newDocument;
};

export const getLegalDocumentById = async (id: string): Promise<TLegalDocument> => {
  const { data } = await api.get(`${endpoints.legalDocuments}/${id}`);

  const document = data.data;

  return document;
};

export const putUpdateLegalDocument = async ({
  id,
  content,
  type,
  version,
  s3Key,
  isActive,
}: {
  id: string;
  content: string;
  type: string;
  version: string;
  s3Key: string;
  isActive: boolean;
}): Promise<TLegalDocument> => {
  const { data } = await api.put(`${endpoints.legalDocuments}/${id}`, {
    content,
    type,
    version,
    s3Key,
    isActive,
  });

  const updatedDocument = data.data;

  return updatedDocument;
};

export const putActivateLegalDocument = async ({
  type,
  version,
}: {
  id: string;
  content: string;
  type: string;
  version: string;
  s3Key: string;
  isActive: boolean;
}): Promise<TLegalDocument> => {
  const { data } = await api.put(`${endpoints.legalDocumentsActivate}/${type}/${version}`);

  const updatedDocument = data.data;

  return updatedDocument;
};

export const putDeactivateLegalDocument = async ({
  type,
  version,
}: {
  id: string;
  content: string;
  type: string;
  version: string;
  s3Key: string;
  isActive: boolean;
}): Promise<TLegalDocument> => {
  const { data } = await api.put(`${endpoints.legalDocumentsDeactivate}/${type}/${version}`);

  const updatedDocument = data.data;

  return updatedDocument;
};
