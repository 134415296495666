export const APP_NAME = 'MyOmnia Backoffice';
export const APP_DESCRIPTION = 'Empowering Your Wholeness';
export const NONE_OF_THE_ABOVE_LABEL = 'None of the above';
export const MODAL_QUESTION_FORM_KEY = 'modal_form_key';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USER_FILTER_BY_OPTIONS = ['Email', 'Organization', 'First name', 'Last name'] as const;

export const OPTION_OTHER = 'Other (please specify)';
export const OPTION_OTHER_BE_VALUE = 'OTHER';

export const INCONCLUSIVE_LAST_LOGIN_TEXT = 'A long time ago...';
