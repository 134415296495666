import { get, isNull, isString, isUndefined } from "lodash";

import { OBJECT_INTERPOLATE_STRING } from "../constants";

const escapeString = (string: string) => JSON.stringify(string).slice(1, -1);

/**
 *
 * @param {String} string JSON configuration
 * @param {{interpolate: String}} options configuration options
 * @returns {Function} Replacer function that receives data and replaces placeholders
 * in template with data
 */
const template = (
  string: string,
  {
    interpolate = OBJECT_INTERPOLATE_STRING,
    valueGetter = (key: string, data: any) => get(data, key, undefined),
  } = {},
) => {
  const regex = new RegExp(`"${interpolate}"|${interpolate}`, "g");

  return (data: any) =>
    string.replaceAll(regex, (match, p1, p2) => {
      const key = (p1 || p2).trim();
      const isPartial = !!p2;
      const value = valueGetter(key, data);

      if (!isUndefined(value) && isPartial) {
        if (isNull(value)) {
          return "";
        }

        /*
         * since we're injecting into an existing string we need escape it to make it okay for JSON,
         * so it is parsed correctly.
         */
        return isString(value) ? escapeString(value) : value;
      }

      if (!isUndefined(value) && !isPartial) {
        if (isNull(value)) {
          return '""';
        }

        return JSON.stringify(value);
      }

      return match;
    });
};

export default template;