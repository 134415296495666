import React, { useCallback } from 'react';
import { VStack } from '@chakra-ui/react';
import { useDocumentEditorStore } from '@/store/documentEditor';
import MonacoEditor from '@/components/organisms/MonacoEditor/MonacoEditor';

const DocumentEditor = (): React.JSX.Element => {
  const { documentStringified, setDocumentStringified, setDocument } = useDocumentEditorStore();

  const handler = useCallback(
    (value: string) => {
      setDocumentStringified(value);
      setDocument(JSON.parse(value));
    },
    [setDocumentStringified, setDocument],
  );

  return (
    <VStack width={'100%'}>
      <MonacoEditor value={documentStringified ?? `{}`} editorId={'document-editor'} handleChange={handler} />
    </VStack>
  );
};

export default DocumentEditor;
