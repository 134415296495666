import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { TApiAssessment } from '@/types/Assessment.types';
import { useDeleteAssessment } from '@/hooks/assessment/assessment';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  assessment?: TApiAssessment;
};

const DeleteAssessmentModal = ({ isOpen, onClose, assessment }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useDeleteAssessment(() => {
    queryClient.invalidateQueries({ queryKey: ['assessment'] });
    onClose?.();
  });

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Delete Assessment" height={'22vh'} width="40%">
      <VStack>
        <Text fontSize={'14px'} fontWeight={'bold'} color={'primary.blueGray'}>
          Are you sure you want to delete assessment?
        </Text>
        <Text fontWeight={'600'} fontSize={'20px'} color={'primary.darkBlue'}>
          {assessment?.title}
        </Text>
        <CustomButton
          label="Yes, Delete"
          onClick={() => {
            if (assessment !== undefined) {
              mutate({ assessmentId: assessment._id });
            }
          }}
          isLoading={isPending}
          backgroundColor="red.500"
          labelColor="white"
          labelHoverColor="black"
        />
      </VStack>
    </ModalContainer>
  );
};

export default DeleteAssessmentModal;
