import { TCreateAssessment } from '@/types/Assessment.types';

const assessmentTemplate = ({ title, slug, type }: TCreateAssessment) => ({
  type: type,
  slug: slug,
  props: {
    title: title,
  },
  children: [
    {
      type: 'QuestionContainer',
      props: {},
      children: [
        {
          type: 'Text',
          children: 'This is your first question. Default health domain is fun.',
          props: {
            variant: 'loraTitle',
          },
        },
        {
          id: '0e299534-67a5-4b8c-ae8a-3c5c5a26316e',
          type: 'Slider',
          props: {
            healthDomain: 'fun',
            name: 'first_question',
            step: 1,
            min: 1,
            max: 10,
          },
        },
      ],
    },
  ],
});

export const getFormattedAssessmentTemplate = (params: TCreateAssessment) => {
  return assessmentTemplate(params);
};
