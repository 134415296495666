import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  DrawerCloseButton,
} from '@chakra-ui/react';

import React, { PropsWithChildren } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  title: string;
  footerContent?: React.ReactNode;
};

const DrawerContainer = ({
  title,
  children,
  footerContent = <></>,
  isOpen,
  onClose,
  onCloseComplete = () => {},
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" onCloseComplete={onCloseComplete} {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <HStack justifyContent="space-between">
            <Text fontSize={16} color="gray.900">
              {title}
            </Text>
            <DrawerCloseButton />
          </HStack>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>{footerContent}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerContainer;
