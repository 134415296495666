'use client';

import { TAssessment } from '@/types/Assessment.types';
import React from 'react';
import Document from './Document';

type Props = {
  document?: TAssessment;
};

const DocumentView = ({ document }: Props) => {
  return (
    <div className="embla">
      <Document json={document as object} />
    </div>
  );
};

export default DocumentView;
