import { FileRoutesByTo } from '../../routeTree.gen.ts';

export type TRoutes = keyof FileRoutesByTo;

export type TRoutesWithLabels = { title: string; href: TRoutes }[];

// Admin
export type TAdminRoutes = {
  adminDashboard: TRoutes;
  assessments: TRoutes;
  eula: TRoutes;
  organizations: TRoutes;
  users: TRoutes;
};

export const adminRoutes: TAdminRoutes = {
  adminDashboard: '/admin/dashboard',
  assessments: '/assessments',
  eula: '/eula',
  organizations: '/organizations',
  users: '/users',
};

export const adminRoutesWithLabels: TRoutesWithLabels = [
  { title: 'Admin Dashboard', href: adminRoutes.adminDashboard },
  { title: 'Assessments', href: adminRoutes.assessments },
  { title: 'EULA', href: adminRoutes.eula },
  { title: 'Organizations', href: adminRoutes.organizations },
  { title: 'Users', href: adminRoutes.users },
];

// Member Care
export type TMemberCareRoutes = { memberCareDashboard: TRoutes };

export const memberCareRoutes: TMemberCareRoutes = {
  memberCareDashboard: '/member-care/dashboard',
};

export const memberCareRoutesWithLabels: TRoutesWithLabels = [
  { title: 'M. Care Dashboard', href: memberCareRoutes.memberCareDashboard },
];

export const nonAuthRoutes: { signIn: TRoutes } = {
  signIn: '/sign-in',
};

const appRoutes = {
  ...adminRoutes,
  ...memberCareRoutes,
  ...nonAuthRoutes,
};

export default appRoutes;
