import { Text, VStack, HStack, Link, Image, Box } from '@chakra-ui/react';
import Slider from './Slider';
import Form from './Form';
import QuestionContainer from './QuestionContainer';
import Markdown from './Markdown/Markdown';
import MultiSelectInput from './MultiSelectInput';
import SingleSelectInput from './SingleSelectInput';

const components: Record<string, any> = {
  Slider,
  MultiSelectInput,
  SingleSelectInput,
  Text,
  VStack,
  HStack,
  Form,
  QuestionContainer,
  ASSESSMENT: Form,
  SCREENER: Form,
  Link,
  Image,
  Box,
  Markdown,
};

export default components;
