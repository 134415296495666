import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { useAssessments } from '@/hooks/assessment/assessment';
import tableTheme from '@/theme/components/table';
import { TApiAssessment } from '@/types/Assessment.types';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useRowSelect } from '@table-library/react-table-library/select';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import CreateAssessmentModal from '@/modules/assessment/CreateAssessmentModal';
import DeleteAssessmentModal from '@/modules/assessment/DeleteAssessmentModal';
import DeleteIcon from '@/components/atoms/DeleteIcon';

const COLUMNS = ['Title', 'Type', 'Slug', 'Delete'];

const Assessments = () => {
  const navigate = useNavigate();
  const theme = useTheme(tableTheme);

  const [assessmentToDelete, setAssessmentToDelete] = useState<TApiAssessment | undefined>();
  const { isLoading, data: assessmentsData, pagination } = useAssessments();

  const { isOpen: isCreateModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();

  const assessmentTableData = assessmentsData?.map((assessment) => ({
    ...assessment,
    id: assessment._id,
  }));

  const rawData = assessmentTableData ?? [];
  const data = { nodes: rawData };

  const onSelectChange = (action: { payload?: { id: string } }) => {
    const itemId = action.payload?.id;
    navigate({ to: `/assessments/${itemId}` });
  };

  const select = useRowSelect(
    { nodes: [] },
    {
      onChange: onSelectChange,
    },
    { isCarryForward: false },
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'Assessments'}
      buttonLabel="Create New Assessment"
      onButtonClick={onCreateModalOpen}
    >
      <Box marginTop={'20px'} marginBottom={'80px'} width={'90%'}>
        {!isLoading && data.nodes.length === 0 && <Text>No organizations created yet.</Text>}

        {data.nodes.length > 0 && (
          <>
            <Table data={data} theme={theme} select={select}>
              {(tableList: any) => (
                <>
                  <Header>
                    <HeaderRow>
                      {COLUMNS.map((column) => (
                        <HeaderCell key={column}>{column}</HeaderCell>
                      ))}
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item: TApiAssessment) => {
                      return (
                        <Row key={item.title} item={item}>
                          <Cell>{item.title}</Cell>
                          <Cell>{item.type?.toLocaleLowerCase()}</Cell>
                          <Cell>{item.slug}</Cell>
                          <Cell>
                            <DeleteIcon
                              onClick={(e: any) => {
                                e.stopPropagation();
                                onDeleteModalOpen();
                                setAssessmentToDelete(item);
                              }}
                              centered={false}
                            />
                          </Cell>
                        </Row>
                      );
                    })}
                  </Body>
                </>
              )}
            </Table>

            <CompactTablePagination pagination={pagination} />
          </>
        )}
      </Box>
      <CreateAssessmentModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
      <DeleteAssessmentModal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} assessment={assessmentToDelete} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/assessments/')({
  component: Assessments,
});
