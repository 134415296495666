import {
  Button,
  Center,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import { TUserFilterOption } from '@/types/User.types';

interface Props {
  title: string;
  options: readonly TUserFilterOption[];
  selectedOption?: string;
  onOptionClick: (option: string) => void;
}

const Dropdown = ({ title, options, selectedOption, onOptionClick }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={'bottom'}
      closeOnBlur={true}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          minWidth={'170px'}
          minHeight={'40px'}
          bg={'transparent'}
          cursor={'pointer'}
          paddingY={0}
          border={'1px solid #E2E8F0'}
          backgroundColor={isOpen ? 'E2E8F0' : '#FFF'}
          _hover={{ backgroundColor: colors.background.lightBlue }}
        >
          <Center height={'100%'}>
            <VStack flexGrow={1} alignItems={'flex-start'} marginLeft={'10px'}>
              <Text fontSize={'14px'} color={'text.darkBlue'} lineHeight={'20px'} paddingX={'5px'} fontWeight={800}>
                {selectedOption ? selectedOption : title}
              </Text>
            </VStack>
            {isOpen ? (
              <ChevronUpIcon boxSize={'5'} color={colors.text.darkBlue} />
            ) : (
              <ChevronDownIcon boxSize={'5'} color={colors.text.darkBlue} />
            )}
          </Center>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        width={'190px'}
        border={0}
        padding={'10px'}
        borderRadius={'8px'}
        boxShadow={'0px 6px 12px 0px #0E006224'}
      >
        {options.map((option) => (
          <Flex
            key={option}
            cursor={'pointer'}
            paddingX={'10px'}
            paddingY={'5px'}
            _hover={{ fontWeight: 800 }}
            fontWeight={option === selectedOption ? 800 : 500}
            onClick={() => {
              onOptionClick(option);
              onClose();
            }}
          >
            {option}
          </Flex>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default Dropdown;
