import svgIcons from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingPage from '@/components/templates/LoadingPage';
import appRoutes from '@/constants/routes/app';
import { useOrganizationById } from '@/hooks/organization/organization';
import InviteOrganizationAdminModal from '@/modules/organization/InviteOrganizationAdminModal';
import colors from '@/theme/colors';
import { Box, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const OrganizationPage = () => {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, isSuccess, data: organizationData } = useOrganizationById(organizationId);

  if (isLoading) return <LoadingPage />;

  return (
    <VStack width={'100%'}>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <Text variant="loraTitle">{organizationData?.name}</Text>
        <CustomButton label="Invite Organization Admin" onClick={onOpen} />
      </HStack>
      <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
        <CustomButton
          isTransparent
          iconBeforeLabelSvgPath={svgIcons.leftArrow}
          labelColor={colors.background.blueGray}
          labelHoverColor="extra.black"
          label="Back to Organizations"
          hoverBackgroundColor="transparent"
          borderHoverColor="transparent"
          onClick={() => navigate({ to: appRoutes.organizations })}
          labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
        />
      </HStack>
      <Box marginTop={'60px'} marginBottom={'80px'} width={'50%'}>
        {!isLoading && isSuccess && (
          <VStack
            paddingX={'40px'}
            paddingY={'20px'}
            bg="white"
            borderRadius={'10px'}
            boxShadow={'0px 10px 80px 0px primary.whiteOptionsShadow'}
          >
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Name:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.name}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Website:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.website ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativeFullName ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative Email:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativeEmail ?? '/'}
              </Text>
            </HStack>
            <HStack
              paddingY={'10px'}
              justifyContent={'space-between'}
              width={'100%'}
              borderBottomWidth={'1px'}
              borderBottomColor={'primary.separatorBlue'}
            >
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Representative Phone:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.representativePhoneNumber ?? '/'}
              </Text>
            </HStack>
            <HStack paddingY={'10px'} justifyContent={'space-between'} width={'100%'}>
              <Text color={'primary.blueGray'} fontWeight={'bold'}>
                Members Count:
              </Text>
              <Text color={'primary.DarkBlue'} fontWeight={'600'}>
                {organizationData?.members.length ?? '/'}
              </Text>
            </HStack>
          </VStack>
        )}
      </Box>
      <InviteOrganizationAdminModal isOpen={isOpen} onClose={onClose} organizationId={organizationId} />
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/$organizationId')({
  component: OrganizationPage,
});
