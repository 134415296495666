import CheckBoxTile from '@/components/atoms/CheckBoxTile';
import { MODAL_QUESTION_FORM_KEY, NONE_OF_THE_ABOVE_LABEL } from '@/constants/stringVars';
import { TAssessmentModal } from '@/types/Assessment.types';
import { IDocumentComponentBase } from '@/types/Document.types';
import { VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type TMultiSelectInputOption = {
  label: string;
  value: number;
};

interface Props extends IDocumentComponentBase {
  options: TMultiSelectInputOption[];
  modal?: TAssessmentModal;
  reverseScoring: boolean;
  hasNoneOfTheAboveOption?: boolean;
}

const MultiSelectInput = ({ options, modal, reverseScoring, hasNoneOfTheAboveOption, name }: Props) => {
  const { control, setValue } = useFormContext();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<TMultiSelectInputOption[]>([]);

  const updateSelectedCheckboxes = useCallback(
    (clickedCheckbox: TMultiSelectInputOption) => {
      const isCheckboxAlreadyChecked = selectedCheckboxes.find(
        (value: TMultiSelectInputOption) => value.label === clickedCheckbox.label,
      );
      if (isCheckboxAlreadyChecked) {
        const newSelectedCheckboxes = selectedCheckboxes.filter((checkbox) => checkbox.label !== clickedCheckbox.label);
        setSelectedCheckboxes(newSelectedCheckboxes);
      } else {
        setSelectedCheckboxes([...(selectedCheckboxes || []), clickedCheckbox]);
      }
    },
    [selectedCheckboxes, setValue],
  );

  // none-of-the-above option is NOT in the selectedCheckboxes
  const [isNoneOfTheAboveSelected, setIsNoneOfTheAboveSelected] = useState(false);

  const maxCheckboxValueSum = useMemo(
    () => options.reduce((acc, curr) => (acc += curr.value), 0),
    [options, reverseScoring],
  );

  useEffect(() => {
    // let totalScore = 0;

    // TODO: score calc needs more work... we should probably have it be done in a new hook similar to useAssessmentResult
    // reverseScoring means that leaving a checkbox BLANK gives you a BETTER score, so, in case of reverseScoring
    // the INITIAL total_score === maxCheckboxValueSum (with all the checkboxes UN-checked);
    // therefore, selecting a checkbox SUBTRACTS value from the total (i.e. maxCheckboxValueSum)
    // if (reverseScoring) {
    //   totalScore = maxCheckboxValueSum - selectedCheckboxes.length * options[0].value;
    // } else {
    //   totalScore = selectedCheckboxes.reduce((acc, curr) => (acc += curr.value), 0);
    // }

    const modalCondition = modal?.selectionCondition;
    let modalUrgencyValue = '';
    if (modalCondition) {
      if (
        (modalCondition === 'any' && selectedCheckboxes.length > 0) ||
        (modalCondition === 'all' && selectedCheckboxes.length === options.length)
      ) {
        modalUrgencyValue = modal.urgency;
      }
    }

    setValue(name, { value: selectedCheckboxes, [MODAL_QUESTION_FORM_KEY]: modalUrgencyValue });
  }, [selectedCheckboxes, modal, options]);

  const noneOfTheAboveOption: TMultiSelectInputOption = useMemo(
    () => ({ value: reverseScoring ? maxCheckboxValueSum : 0, label: NONE_OF_THE_ABOVE_LABEL }),
    [maxCheckboxValueSum],
  );

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={() => (
        <VStack flexWrap={'wrap'} gap={5}>
          {options.map((checkboxOption) => (
            <CheckBoxTile<TMultiSelectInputOption>
              key={checkboxOption.label}
              checkboxOption={checkboxOption}
              onClick={updateSelectedCheckboxes}
              isDisabled={isNoneOfTheAboveSelected}
              isChecked={
                selectedCheckboxes.length
                  ? !!selectedCheckboxes.find((value: TMultiSelectInputOption) => value.label === checkboxOption.label)
                  : false
              }
            />
          ))}

          {hasNoneOfTheAboveOption && (
            <CheckBoxTile<TMultiSelectInputOption>
              key={'none-of-the-above'}
              checkboxOption={noneOfTheAboveOption}
              onClick={() => setIsNoneOfTheAboveSelected((prev) => !prev)}
              isChecked={isNoneOfTheAboveSelected}
              isDisabled={!!selectedCheckboxes.length}
            />
          )}
        </VStack>
      )}
    />
  );
};

export default MultiSelectInput;
