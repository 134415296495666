import { APP_NAME } from "@/constants/stringVars";

const authMessages = {
  signInTitle: `Welcome to ${APP_NAME} - a wholeness platform.`,
  signOutDescription: `We invite you to join us and let ${APP_NAME} help your organization with everything related to mental health.`,
  email: 'Email',
  password: 'Password',
  signIn: 'Sign In',
  signOut: 'Sign Out',
  contactSupport: 'Contact Support',
  forgotPassword: 'Forgot Password?',
  forgotPwDescriptionOne: 'No worries! Just enter your email address below, and we’ll send you a link to reset your password.',
  forgotPwDescriptionTwo: 'If you don’t receive an email, please check your spam folder or try entering your email address again.',
  submit: 'Submit',
  goBack: 'Go back',
}

export default authMessages;