import logoVariants from '@/assets/img/logo';
import { Flex, Image } from '@chakra-ui/react';

const LoadingPage = ({ width = '100vw', height = '100vh' }) => {
  return (
    <Flex
      width={width}
      height={height}
      flexDirection="column"
      alignItems={'center'}
      justifyContent={'center'}
      top={0}
      left={0}
      pos={'absolute'}
    >
      <Image
        animation={'alternate-opacity 0.7s infinite ease-out alternate'}
        src={logoVariants.purple252x80}
        width={'252px'}
        height={'80px'}
        alt="my-omnia-logo"
      />
    </Flex>
  );
};

export default LoadingPage;
