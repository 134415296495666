import { useDeactivatedUsers, useUserActivation } from '@/hooks/user/users';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import UsersTable from './UsersTable';
import { TUserActivationToggle, TUserFilterOption } from '@/types/User.types';
import svgIcons from '@/assets/svg/icons';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import colors from '@/theme/colors';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import CustomButton from '@/components/atoms/CustomButton';

interface Props {
  filterOption?: TUserFilterOption;
  filterText: string;
  setIsLoadingSearch: Dispatch<SetStateAction<boolean>>;
}

const DeactivatedUsersTable = ({ filterOption, filterText, setIsLoadingSearch }: Props) => {
  const { isLoading, data, pagination, refetch, isRefetching } = useDeactivatedUsers({ filterOption, filterText });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activatingUser, setActivatingUser] = useState<TUserActivationToggle>();

  const { mutate, isPending } = useUserActivation();

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  useEffect(() => {
    setIsLoadingSearch(isSearching);
  }, [isSearching]);

  const onActivationSuccess = () => {
    setIsModalOpened(false);
    refetch();
  };

  const activateSelectedUser = () => {
    if (activatingUser) {
      mutate({ userId: activatingUser.id }, { onSuccess: onActivationSuccess });
    }
  };

  return (
    <>
      <UsersTable
        users={data}
        pagination={pagination}
        isLoading={isLoading}
        isRefetching={isRefetching}
        isSearching={isSearching}
        invalidatedUserId={activatingUser?.id}
        ActionIcon={(userToActivate: TUserActivationToggle) => (
          <CustomButton
            label={'Activate'}
            labelColor={colors.extra.white}
            iconBeforeLabelSvgPath={svgIcons.userActivate}
            onClick={() => {
              setIsModalOpened(true);
              setActivatingUser(userToActivate);
            }}
            width={'110px'}
            height={'35px'}
            noShadow
          />
        )}
      />
      <ConfirmationModal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setActivatingUser(undefined);
        }}
        title="Confirm Activation"
        confirmButtonText="Activate"
        description={`This will activate user ${activatingUser?.firstName} ${activatingUser?.lastName}`}
        onConfirm={activateSelectedUser}
        confirmButtonColor="primary.500"
        confirmLoading={isPending}
      />
    </>
  );
};

export default DeactivatedUsersTable;
