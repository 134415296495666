import { commands } from "@uiw/react-md-editor";

import { BoldIcon } from "./assets/BoldIcon";
import { ItalicIcon } from "./assets/ItalicIcon";
import { QuoteIcon } from "./assets/QuoteIcon";
import { CodeIcon } from "./assets/CodeIcon";
import { LinkIcon } from "./assets/LinkIcon";
import { OrderedListIcon } from "./assets/OrderedListIcon";
import { UnorderedListIcon } from "./assets/UnorderedListIcon";
import { CheckedListIcon } from "./assets/CheckedListIcon";


const customBoldCommand = {
  ...commands.bold,
  icon: <BoldIcon />,
};

const customItalicCommand = {
  ...commands.italic,
  icon: <ItalicIcon />,
};

const customQuoteCommand = {
  ...commands.quote,
  icon: <QuoteIcon />,
};

const customCodeCommand = {
  ...commands.code,
  icon: <CodeIcon />,
};

const customLinkCommand = {
  ...commands.link,
  icon: <LinkIcon />,
};

const customOrderedListCommand = {
  ...commands.orderedListCommand,
  icon: <OrderedListIcon />,
};

const customUnorderedListCommand = {
  ...commands.unorderedListCommand,
  icon: <UnorderedListIcon />,
};

const customCheckedListCommand = {
  ...commands.checkedListCommand,
  icon: <CheckedListIcon />,
};

export {
  customBoldCommand,
  customItalicCommand,
  customQuoteCommand,
  customCodeCommand,
  customLinkCommand,
  customOrderedListCommand,
  customUnorderedListCommand,
  customCheckedListCommand,
}