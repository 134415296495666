'use client';

import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import createElementFromJson from './createElementFromJson';
import { TJsonDocumentBase } from '@/types/Document.types';

type Props = {
  components: Record<string, React.ReactNode>;
  config?: any;
};

type AssessmentProviderProps = PropsWithChildren<Props>;

const DocumentProviderContext = createContext<AssessmentProviderProps>({ components: {} });
DocumentProviderContext.displayName = 'DocumentProviderContext';

export const useDocumentProviderComponents = (): AssessmentProviderProps => {
  const context = useContext(DocumentProviderContext);

  if (context === undefined) {
    throw new Error('useDocumentProviderComponents must be used within a DocumentProvider');
  }

  return context;
};

export const useCreateElementFromJson = () => {
  const { components, config } = useDocumentProviderComponents();

  return (json: TJsonDocumentBase) => createElementFromJson(json, components, config);
};

const DocumentProvider = ({ components, children, config }: AssessmentProviderProps) => {
  const memoizedValue = useMemo(
    () => ({
      components,
      config,
    }),
    [components, config],
  );

  return <DocumentProviderContext.Provider value={memoizedValue}>{children}</DocumentProviderContext.Provider>;
};

export default DocumentProvider;
