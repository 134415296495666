import { Link as ChakraLink, useStyles } from "@chakra-ui/react";

interface Props {
  node: any;
}

/**
 * Links that are part of Markdown are (for now) all external
 */
const Link = ({ node, ...props }: Props) => {
  const styles = useStyles();

  return <ChakraLink sx={styles.link} isExternal fontWeight={'bold'} textColor={'#4e1aef'} {...props} />;
};

export default Link;
