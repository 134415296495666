import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import tableTheme from '@/theme/components/table';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useTheme } from '@table-library/react-table-library/theme';
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useRowSelect } from '@table-library/react-table-library/select';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import appRoutes from '@/constants/routes/app';
import { useLegalDocuments } from '@/hooks/legal/legal';
import CreateLegalDocumentModal from '@/modules/legal/CreateLegalDocumentModal/CreateLegalDocumentModal';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import { TLegalDocument } from '@/types/Legal.types';

const COLUMNS = ['Type', 'Version', 'Is Active', 'Created At'];

const EulaDocuments = () => {
  const navigate = useNavigate();
  const theme = useTheme(tableTheme);

  const { isOpen: isCreateModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useDisclosure();

  const { isLoading, data: documents, pagination } = useLegalDocuments();

  const documentsTableData = documents?.map((document) => ({
    ...document,
    id: document._id,
  }));
  const rawData = documentsTableData ?? [];
  const data = { nodes: rawData };

  const onSelectChange = (action: { payload?: { id: string } }) => {
    const itemId = action.payload?.id;
    navigate({ to: `${appRoutes.eula}/${itemId}` });
  };

  const select = useRowSelect(
    { nodes: [] },
    {
      onChange: onSelectChange,
    },
    { isCarryForward: false },
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'EULA Documents'}
      buttonLabel="Create New Document"
      onButtonClick={onCreateModalOpen}
    >
      <Box marginTop={'20px'} marginBottom={'80px'} width={'90%'}>
        {!isLoading && data.nodes.length === 0 && <Text>No Documents created yet.</Text>}

        {data.nodes.length > 0 && (
          <>
            <Table data={data} theme={theme} select={select}>
              {(tableList: any) => (
                <>
                  <Header>
                    <HeaderRow>
                      {COLUMNS.map((column: string) => (
                        <HeaderCell key={column}>{column}</HeaderCell>
                      ))}
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item: TLegalDocument & { id: string }) => (
                      <Row key={item.id} item={item}>
                        <Cell>{item.type}</Cell>
                        <Cell>{item.version}</Cell>
                        <Cell>{item.isActive ? 'Yes' : 'No'}</Cell>
                        <Cell>{item.createdAt}</Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>

            <CompactTablePagination pagination={pagination} />
          </>
        )}
      </Box>

      <CreateLegalDocumentModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/eula/')({
  component: EulaDocuments,
});
