import { useStore, createStore } from 'zustand';
import { persist, devtools } from 'zustand/middleware';
import { editor } from 'monaco-editor';
import { TApiAssessment, TAssessment } from '@/types/Assessment.types';

interface IDocumentEditorStoreState {
  documentApiResponse?: TApiAssessment;
  initiateDocument: (apiResponse: TApiAssessment) => void;
  document?: TAssessment;
  setDocument: (document: TAssessment) => void;
  documentStringified?: string;
  setDocumentStringified: (document: string) => void;
  carouselItemIndex: number;
  setCarouselItemIndex: (index: number) => void;
  resetState: () => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  editorMarkers: editor.IMarker[];
  setEditorMarkers: (markers: editor.IMarker[]) => void;
}

const initState = {
  document: undefined,
  documentStringified: undefined,
  documentApiResponse: undefined,
  carouselItemIndex: 0,
  hasError: false,
  editorMarkers: [],
};

const config = {
  name: `${import.meta.env.MODE}_backoffice_document_editor_store`,
};

export const documentEditorStore = createStore<IDocumentEditorStoreState>()(
  devtools(
    persist(
      (set) => ({
        ...initState,
        setDocument: (document: TAssessment) => {
          set(() => ({ document }));
        },
        initiateDocument: (apiResponse: TApiAssessment) => {
          set(() => ({
            document: apiResponse.data,
            documentApiResponse: apiResponse,
            documentStringified: JSON.stringify(apiResponse.data),
          }));
        },
        setDocumentStringified: (document: string) => {
          set(() => ({ documentStringified: document }));
        },
        setCarouselItemIndex: (index: number) => {
          set(() => ({ carouselItemIndex: index }));
        },
        setHasError: (hasError: boolean) => {
          set(() => ({ hasError }));
        },
        resetState: () => {
          set(() => ({ document: undefined }));
        },
        setEditorMarkers: (markers: editor.IMarker[]) => {
          set(() => ({ editorMarkers: markers }));
        },
      }),
      config,
    ),
  ),
);

export const useDocumentEditorStore = (): IDocumentEditorStoreState => useStore(documentEditorStore);
