import roles, { rolesDisplayLabel, TUserRole } from '@/constants/roles';
import { TUser, TUserFilterOption } from '@/types/User.types';

const getHighestUserRole = (userRoles: TUserRole[]) => {
  if (userRoles.length === 1) return userRoles[0];
  if (userRoles.includes(roles.superAdmin)) return roles.superAdmin;
  if (userRoles.includes(roles.memberCare)) return roles.memberCare;
  if (userRoles.includes(roles.orgAdmin)) return roles.orgAdmin;
  return roles.orgEmployee;
};

export const getHighestUserRoleDisplay = (userRoles: TUserRole[]) => {
  return rolesDisplayLabel[getHighestUserRole(userRoles)];
};

export const getBeUserFieldNameFromFilterOption = (
  filterOption: TUserFilterOption,
): keyof TUser | 'organizationName' => {
  switch (filterOption) {
    case 'Email':
      return 'email';

    case 'First name':
      return 'firstName';

    case 'Last name':
      return 'lastName';

    case 'Organization':
      return 'organizationName';

    default:
      return 'email';
  }
};

export const generateRandomString = ({ length = 6 }): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
