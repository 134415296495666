import AppPageTemplate from '@/components/templates/AppPageTemplate';
import SidebarContentWrapper from '@/modules/sidebar/SidebarContentWrapper';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

const AuthenticatedLayout = () => {
  return (
    <AppPageTemplate>
      <SidebarContentWrapper>
        <Outlet />
      </SidebarContentWrapper>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated')({
  component: AuthenticatedLayout,
  beforeLoad: async ({ context: { auth } }) => {
    if (!auth?.isAuthenticated) {
      throw redirect({
        to: '/sign-in',
      });
    }
  },
});
