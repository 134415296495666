import template from "./template";
import { OBJECT_INTERPOLATE_STRING } from "../constants";

/**
 * This function turns an object into a string and interpolates that string with data.
 * @param {Object} object - Any object
 * @param {Object} data - Object containing possible variable names to format and its values
 * @returns the original object with the data interpolated into it.
 */
const formatObjectWithContextData = ({ object, data }: any) => {
  if (!data || !Object.keys(data).length) return object;

  const string = JSON.stringify(object);
  const formatter = template(string, {
    interpolate: OBJECT_INTERPOLATE_STRING,
  });
  const formatted = formatter(data);
  const parsed = JSON.parse(formatted);

  return parsed;
};

export default formatObjectWithContextData;
