const roles = {
  superAdmin: 'superadmin',
  memberCare: 'membercare',
  orgAdmin: 'organizationadmin',
  orgEmployee: 'organizationemployee',
} as const;

export type TUserRole = (typeof roles)[keyof typeof roles];

export const rolesDisplayLabel: Record<TUserRole, string> = {
  membercare: 'Member Care',
  superadmin: 'Super Admin',
  organizationadmin: 'Org Admin',
  organizationemployee: 'Org Employee',
};

export default roles;
