import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import AppPageTemplate from '../AppPageTemplate';

const NotFoundPage = () => {
  return (
    <AppPageTemplate>
      <VStack maxWidth={{ md: '500px' }} spacing={'30px'} marginTop={'30px'}>
        <Text textAlign={'center'} variant={'loraTitle'}>
          Oops!
        </Text>
        <Text variant={'urbanistSemiBoldSmallTitle'}>404 page not found.</Text>
        <Text textAlign={'center'} variant={'urbanistSemiBold'}>
          Make sure you typed the address correctly or go back to your previous page.
        </Text>
      </VStack>
    </AppPageTemplate>
  );
};

export default NotFoundPage;
