import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { Box, Button, HStack, Input, InputGroup, InputRightElement, Spinner, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import CustomButton from '@/components/atoms/CustomButton';
import { useRef, useState } from 'react';
import ActivatedUsersTable from '@/modules/user/ActivatedUsersTable';
import DeactivatedUsersTable from '@/modules/user/DeactivatedUsersTable';
import { TUserFilterOption } from '@/types/User.types';
import { USER_FILTER_BY_OPTIONS } from '@/constants/stringVars';
// import Dropdown2 from '@/components/atoms/Dropdown/Dropdown2';
import Dropdown from '@/components/atoms/Dropdown';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { debounce } from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';

const Users = () => {
  const [usersSelection, setUsersSelection] = useState<'activated' | 'deactivated'>('activated');

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [filterOption, setFilterOption] = useState<TUserFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');
  const debouncedSetFilterText = debounce((value) => setFilterText(value), 500);

  const filterTextToSearch = filterText.length < MIN_CHARS_TO_DO_SEARCH ? '' : filterText;

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  return (
    <SidebarItemViewTemplate title={`${usersSelection === 'activated' ? 'Activated' : 'Deactivated'} Users`}>
      <HStack width={'90%'} marginTop={'20px'} justifyContent={'space-between'}>
        <HStack
          backgroundColor={'white'}
          padding={'8px'}
          borderRadius={'8px'}
          gap={'10px'}
          style={{ marginLeft: '-70px' }}
          boxShadow={'0px 2px 2px 0px #7C9EBC80'}
        >
          <CustomButton
            label={'Activated'}
            onClick={() => setUsersSelection('activated')}
            backgroundColor={usersSelection === 'activated' ? 'primary.500' : 'white'}
            labelColor={usersSelection === 'activated' ? 'white' : 'primary.500'}
            isTransparent={usersSelection !== 'activated'}
          />
          <CustomButton
            label={'Deactivated'}
            onClick={() => setUsersSelection('deactivated')}
            backgroundColor={usersSelection === 'deactivated' ? 'primary.500' : 'white'}
            labelColor={usersSelection === 'deactivated' ? 'white' : 'primary.500'}
            isTransparent={usersSelection !== 'deactivated'}
          />
        </HStack>

        <HStack gap={'15px'}>
          {/* <Dropdown2
            label="Select filter"
            value={{ value: filterOption, label: filterOption ?? '' }}
            options={USER_FILTER_BY_OPTIONS.map((opt) => ({ label: opt, value: opt }))}
            onSelect={(newOption) => setFilterOption(newOption.value as TUserFilterOption)}
          /> */}
          <Dropdown
            title="Select filter"
            selectedOption={filterOption}
            onOptionClick={(option) => setFilterOption(option as TUserFilterOption)}
            options={USER_FILTER_BY_OPTIONS}
          />
          <InputGroup>
            <Input
              ref={searchInputRef}
              width={'300px'}
              backgroundColor={'white'}
              placeholder={
                filterOption === undefined
                  ? 'Please select a filter'
                  : `Search users by ${filterOption !== undefined ? filterOption : '...'}`
              }
              _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              defaultValue={filterText}
              onChange={(e) => debouncedSetFilterText(e.target.value)}
              isDisabled={filterOption === undefined}
              _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
            />

            <InputRightElement width="55px" height={'100%'}>
              <VStack backgroundColor={'white'}>
                {isLoadingSearch ? (
                  <Spinner />
                ) : (
                  <Button
                    height={'30px'}
                    onClick={() => {
                      setFilterText('');
                      if (searchInputRef.current) searchInputRef.current.value = '';
                    }}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </VStack>
            </InputRightElement>
          </InputGroup>
        </HStack>
      </HStack>
      {/* TODO: should the width of the container Box be ={'90%'} ?? */}
      <Box marginTop={'20px'} marginBottom={'80px'} width={'90%'}>
        {usersSelection === 'activated' ? (
          <ActivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        ) : (
          <DeactivatedUsersTable
            filterOption={filterOption}
            filterText={filterTextToSearch}
            setIsLoadingSearch={setIsLoadingSearch}
          />
        )}
      </Box>
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/users/')({
  component: Users,
});
