import CustomButton from '@/components/atoms/CustomButton';
import DocumentComponentsDrawer from '@/components/organisms/DocumentComponentsDrawer/DocumentComponentsDrawer';
import LoadingPage from '@/components/templates/LoadingPage';
import appRoutes from '@/constants/routes/app';
import { useAssessmentById, useUpdateAssessment } from '@/hooks/assessment/assessment';
import DocumentEditor from '@/modules/assessment/Document/DocumentEditor/DocumentEditor';
import DocumentView from '@/modules/assessment/Document/DocumentView';
import { useDocumentEditorStore } from '@/store/documentEditor';
import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

const AssessmentView = () => {
  const { assessmentId } = Route.useParams();

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, data, isSuccess } = useAssessmentById(assessmentId);
  const { isPending, mutate } = useUpdateAssessment();

  const { document, initiateDocument, hasError } = useDocumentEditorStore();

  useEffect(() => {
    if (isSuccess && !!data) {
      initiateDocument(data);
    }
  }, [data, isSuccess, initiateDocument]);

  if (isLoading) return <LoadingPage />;

  return (
    <HStack width={'100%'} height={'100%'}>
      <HStack width={'100%'} height={'100%'} alignItems={'flex-start'}>
        <VStack flex={1} height={'100%'}>
          <HStack justifyContent={'space-between'} width={'100%'}>
            <Text fontSize={'20px'}>{data?.title}</Text>
            <HStack spacing={'20px'}>
              <CustomButton
                isLoading={isPending}
                label="Save Changes"
                backgroundColor="text.mediumBlue"
                labelColor="white"
                labelHoverColor="text.darkBlue"
                onClick={() => {
                  mutate({ assessmentId });
                }}
                disabled={hasError}
              />
              <CustomButton
                label="Live Preview"
                backgroundColor="secondary.600"
                labelColor="white"
                labelHoverColor="text.darkBlue"
                onClick={() =>
                  navigate({
                    to: `${appRoutes.assessments}/${assessmentId}/preview`,
                  })
                }
                disabled={hasError}
              />
            </HStack>
          </HStack>
          <VStack width={'60vw'} borderWidth={'3px'} borderRadius={'8px'} borderColor={'primary.500'}>
            <VStack margin={'20px'}>
              <DocumentView document={document} />
            </VStack>
          </VStack>
        </VStack>
        <VStack width={'100%'} alignItems={'flex-start'}>
          <CustomButton
            label="Component Templates"
            onClick={() => {
              onOpen();
            }}
          />
          <DocumentEditor />
        </VStack>
      </HStack>

      <DocumentComponentsDrawer isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/assessments/$assessmentId/')({
  component: AssessmentView,
});
