import React, { PropsWithChildren } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  title?: string;
  footerContent?: React.ReactNode;
  onClose?: () => void;
  isCentered?: boolean;
  width?: string;
  height?: string;
};

const ModalContainer = ({
  isOpen = false,
  children,
  title,
  footerContent,
  onClose = () => {},
  isCentered = true,
  width = '50%',
  height = '50vh',
}: PropsWithChildren<Props>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay>
        <ModalContent backgroundColor={'#F8FCFF'} minWidth={width} minHeight={height}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton size={'20px'} padding={'10px'} />
          <ModalBody>{children}</ModalBody>
          {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ModalContainer;
