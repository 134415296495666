import { createFileRoute, useRouter } from '@tanstack/react-router';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { getOrganizations } from '@/utils/queryOptions';

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useRowSelect } from '@table-library/react-table-library/select';
import tableTheme from '@/theme/components/table';
import LoadingPage from '@/components/templates/LoadingPage';
import DeleteIcon from '@/components/atoms/DeleteIcon';
import { useOrganizations } from '@/hooks/organization/organization';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import CreateOrganizationModal from '@/modules/organization/CreateOrganizationModal';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';

const deleteItem = (id: string) => console.log(`deleting item: ${id}`); // TODO: Delete an Organization

const COLUMNS: string[] = ['Name', 'Website', 'Representative Email', 'Members Count', 'Delete'];

const Organizations = () => {
  const router = useRouter();
  const theme = useTheme(tableTheme);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, data: organizationsResponse, pagination } = useOrganizations();

  const organizationsTableData = organizationsResponse?.map((organization) => ({
    ...organization,
    id: organization._id,
  }));

  const data = { nodes: organizationsTableData ?? [] };

  const onSelectChange = (action: { payload?: { id: string } }) => {
    const orgId = action.payload?.id;
    router.navigate({ to: `/organizations/${orgId}` });
  };

  const select = useRowSelect(
    { nodes: [] },
    {
      onChange: onSelectChange,
    },
    { isCarryForward: false },
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate title={'Organizations'} buttonLabel="Create New Organization" onButtonClick={onOpen}>
      <Box marginTop={'20px'} marginBottom={'80px'} width={'90%'}>
        {!isLoading && data.nodes.length === 0 && <Text>No organizations created yet.</Text>}

        {data.nodes.length > 0 && (
          <>
            <Table data={data} theme={theme} select={select}>
              {(tableList: any) => (
                <>
                  <Header>
                    <HeaderRow>
                      {COLUMNS.map((column: string) => (
                        <HeaderCell key={column}>{column}</HeaderCell>
                      ))}
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item: any) => {
                      return (
                        <Row key={item._id} item={item}>
                          <Cell>{item.name}</Cell>
                          <Cell>{item.website}</Cell>
                          <Cell>{item.representativeEmail}</Cell>
                          <Cell>
                            <Text marginLeft={'40px'}>{item.members.length}</Text>
                          </Cell>
                          <Cell>
                            <DeleteIcon
                              onClick={(e: any) => {
                                e.stopPropagation();
                                deleteItem(item._id);
                              }}
                              centered={false}
                            />
                          </Cell>
                        </Row>
                      );
                    })}
                  </Body>
                </>
              )}
            </Table>

            <CompactTablePagination pagination={pagination} />
          </>
        )}
      </Box>
      <CreateOrganizationModal isOpen={isOpen} onClose={onClose} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/')({
  component: Organizations,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getOrganizations()),
});
