import CustomButton from '@/components/atoms/CustomButton';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { APP_NAME } from '@/constants/stringVars';
import { useAuthContext } from '@/context/auth/useAuthContext';
import authMessages from '@/messages/auth';
import { Link as ChakraLink } from '@chakra-ui/react';
import { createFileRoute, Link } from '@tanstack/react-router';

const HomeLayout = () => {
  const { signOut } = useAuthContext();

  return (
    <SidebarItemViewTemplate
      title={`Welcome to ${APP_NAME}`}
      customButton={
        <ChakraLink to={''} as={Link} _hover={{ textDecoration: '' }}>
          <CustomButton label={authMessages.signOut} onClick={signOut} />
        </ChakraLink>
      }
    ></SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/')({
  component: HomeLayout,
});
