import { useState } from 'react';

import { Box, Flex, FlexProps } from '@chakra-ui/react';
import SvgIcon from '../SvgIcon';
import colors from '@/theme/colors';
import svgIcons from '@/assets/svg/icons';

const DeleteIcon = ({ onClick, centered = true }: { onClick: FlexProps['onClick']; centered?: boolean }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      marginLeft={centered ? '20px' : 0}
      padding={'5px 10px'}
      justifyContent={centered ? 'center' : 'start'}
      width={'40%'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor={'pointer'}
      onClick={onClick}
    >
      <Box paddingLeft={centered ? '10px' : 0} paddingTop={'4px'} paddingRight={'5px'}>
        <SvgIcon iconPath={svgIcons.trashCan} color={isHovered ? colors.extra.red : colors.text.darkBlue} size="20" />
      </Box>
    </Flex>
  );
};

export default DeleteIcon;
