import CustomButton from '@/components/atoms/CustomButton';
import MarkdownEditor from '@/components/organisms/MarkdownEditor/MarkdownEditor';
import LoadingPage from '@/components/templates/LoadingPage';
import { useLegalDocumentById, useUpdateLegalDocument } from '@/hooks/legal/legal';
import EulaActivationConfirmModal from '@/modules/legal/eula/EulaActivationConfirmModal';
import EulaDocPreview from '@/modules/legal/eula/EulaDocPreview';
import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';

const EulaDocument = () => {
  const { id: documentId } = Route.useParams();
  const { isLoading, data, isSuccess } = useLegalDocumentById(documentId);
  const { isPending, mutate } = useUpdateLegalDocument(() => {
    window.location.reload();
  });

  const { isOpen: isActivateModalOpen, onClose: onActivateModalClose, onOpen: onActivateModalOpen } = useDisclosure();

  const [markdownEditorValue, setMarkdownEditorValue] = useState<string | undefined>(data?.content);

  useEffect(() => {
    if (data && isSuccess) {
      setMarkdownEditorValue(data.content);
    }
  }, [data, isSuccess]);

  const onSaveChanges = () => {
    if (isUndefined(data)) return;
    mutate({
      id: documentId,
      isActive: data?.isActive,
      content: markdownEditorValue ?? data?.content,
      type: data?.type,
      version: data?.version,
      s3Key: data?.s3Key,
    });
  };

  const onActivateDeactivate = () => {
    onActivateModalOpen();
  };

  if (isLoading) return <LoadingPage />;

  return (
    <VStack width={'100%'}>
      {isSuccess && !isUndefined(data) && (
        <VStack width={'100%'}>
          <HStack justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <Text variant="loraSectionTitle">{data?.type?.replaceAll('_', ' ')}</Text>
            <HStack gap={'20px'}>
              <CustomButton
                backgroundColor={'primary.500'}
                label="Save Changes"
                onClick={onSaveChanges}
                disabled={markdownEditorValue === data?.content}
                isLoading={isPending}
              />
              {/* User needs to save changes first before activating document */}
              <CustomButton
                backgroundColor={data?.isActive ? 'red' : 'secondary.500'}
                disabled={markdownEditorValue !== data?.content}
                label={`${data?.isActive ? 'Deactivate' : 'Activate'} Document`}
                onClick={onActivateDeactivate}
              />
            </HStack>
          </HStack>
          <HStack
            alignItems={'flex-start'}
            justify={'space-between'}
            width={'100%'}
            marginTop={'20px'}
            spacing={'20px'}
          >
            <VStack width={'820px'}>
              <VStack height={'50vh'} width={'100%'} marginBottom={'10px'}>
                <MarkdownEditor content={markdownEditorValue ?? ''} setContent={setMarkdownEditorValue} />
              </VStack>
              <EulaDocPreview content={markdownEditorValue ?? ''} />
            </VStack>
            <VStack flex={1} alignItems={'flex-start'} spacing={'20px'}>
              <VStack alignItems={'flex-start'}>
                <Text fontWeight={'semibold'} fontSize={'12px'}>
                  S3Key
                </Text>
                <Text fontSize={'14px'}>{data?.s3Key}</Text>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Text fontWeight={'semibold'} fontSize={'12px'}>
                  Version
                </Text>
                <Text fontSize={'14px'}>{data?.version}</Text>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Text fontWeight={'semibold'} fontSize={'12px'}>
                  Created At
                </Text>
                <Text fontSize={'14px'}>{data?.createdAt}</Text>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Text fontWeight={'semibold'} fontSize={'12px'}>
                  Updated At
                </Text>
                <Text fontSize={'14px'}>{data?.updatedAt}</Text>
              </VStack>
              <VStack alignItems={'flex-start'}>
                <Text fontWeight={'semibold'} fontSize={'12px'}>
                  Is Live
                </Text>
                <Text fontSize={'14px'}>{data?.isActive ? 'Yes' : 'No'}</Text>
              </VStack>
            </VStack>
          </HStack>
          <EulaActivationConfirmModal document={data} isOpen={isActivateModalOpen} onClose={onActivateModalClose} />
        </VStack>
      )}
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/eula/$id')({
  component: EulaDocument,
});
