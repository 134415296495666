import {
  getAllLegalDocuments,
  getLegalDocumentById,
  postCreateLegalDocument,
  putActivateLegalDocument,
  putDeactivateLegalDocument,
  putUpdateLegalDocument,
} from '@/services/api/requests/legal';
import { usePagination } from '../usePagination';
import { TLegalDocument } from '@/types/Legal.types';
import useToast from '../useToast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TCustomAxiosError } from '@/services/api';

export const useLegalDocuments = () => {
  return usePagination({ query: getAllLegalDocuments, queryKey: 'legal-documents' });
};

export const useCreateLegalDocument = (onSuccessCallback?: (document: TLegalDocument) => void) => {
  const toast = useToast();

  const { isPending, data, mutate } = useMutation({
    mutationFn: async ({ type }: { type: string }) => {
      const data = await postCreateLegalDocument({ type });
      return data;
    },
    onSuccess: (document) => {
      if (onSuccessCallback) {
        onSuccessCallback(document);
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Creating legal document error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { isPending, data, mutate };
};

export const useLegalDocumentById = (
  id: string,
): { data: TLegalDocument | undefined; isLoading: boolean; isError: boolean; isSuccess: boolean } => {
  const { isLoading, data, isError, isSuccess } = useQuery({
    queryKey: ['legal-documents', id],
    queryFn: async () => {
      const document = await getLegalDocumentById(id);

      return document;
    },
  });

  return { isLoading, data, isError, isSuccess };
};

export const useUpdateLegalDocument = (onSuccessCallback?: () => void) => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (doc: {
      id: string;
      content: string;
      type: string;
      version: string;
      s3Key: string;
      isActive: boolean;
    }) => {
      const data = await putUpdateLegalDocument(doc);
      return data;
    },
    onSuccess: () => {
      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Updating legal document error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { isPending, mutate, isError };
};

export const useToggleActivateLegalDocument = (onSuccessCallback?: () => void) => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (doc: {
      id: string;
      content: string;
      type: string;
      version: string;
      s3Key: string;
      isActive: boolean;
    }) => {
      const data = (await doc.isActive) ? putDeactivateLegalDocument(doc) : putActivateLegalDocument(doc);
      return data;
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Toggle activate legal document error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { isPending, mutate, isError };
};
