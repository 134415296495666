import { Divider, Flex, Image, VStack } from '@chakra-ui/react';
import { useRouterState } from '@tanstack/react-router';
import { SIDEBAR_WIDTH } from '@/constants/dimensions';
import SidebarItem from './SidebarItem';
import { useAuthContext } from '@/context/auth/useAuthContext';
import authMessages from '@/messages/auth';
import { adminRoutesWithLabels, memberCareRoutesWithLabels, TRoutesWithLabels } from '@/constants/routes/app';
import { useMemo } from 'react';

const Sidebar = () => {
  const routerState = useRouterState();
  const pathName = routerState.location.pathname;
  const isHomePage = pathName === '/';

  const { signOut, isMemberCare, isSuperAdmin } = useAuthContext();

  const roleSpecificSidebarItems = useMemo(() => {
    const allRoutes: TRoutesWithLabels = [];
    if (isSuperAdmin) allRoutes.push(...adminRoutesWithLabels);
    if (isMemberCare) allRoutes.push(...memberCareRoutesWithLabels);
    return allRoutes;
  }, [isSuperAdmin, isMemberCare]);

  return (
    <Flex
      p={4}
      h="100vh"
      boxShadow="0px 0px 20px 0px primary.whiteOptionsShadow"
      w={SIDEBAR_WIDTH}
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex px="1%" flexDir="column" w="100%" h="100vh" alignItems={'center'} mb={3}>
        <Flex mt={4} align="center">
          <Image src="/img/logo/my-omnia-purple-252x80.png" alt="MyOmnia Logo" width={'140px'} height={'45px'} />
        </Flex>

        <Divider marginY={2} />

        {/* Sidebar items */}
        <VStack padding={0} margin={0} justifyContent={'space-between'} height={'100%'} width={'100%'}>
          <VStack gap={5} padding={0} margin={0} width={'100%'}>
            <SidebarItem key={'home'} title={'Home'} href={'/'} isSelected={isHomePage} />
            {roleSpecificSidebarItems.map((item) => {
              const currentItemRoute = item.href;
              const isSelected = isHomePage ? false : pathName.includes(currentItemRoute);

              return (
                <SidebarItem key={item.title} title={item.title} href={currentItemRoute} isSelected={isSelected} />
              );
            })}
          </VStack>
          <SidebarItem
            key={'sign-out'}
            title={authMessages.signOut}
            href={'/'}
            hoverStyle={{
              backgroundColor: '',
              color: 'black',
            }}
            onClick={signOut}
            isSelected={false}
          />
        </VStack>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
