import './markdown_editor.css';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { HeadingIcon } from './assets/HeadingIcon';
import {
  customBoldCommand,
  customCheckedListCommand,
  customCodeCommand,
  customItalicCommand,
  customLinkCommand,
  customOrderedListCommand,
  customQuoteCommand,
  customUnorderedListCommand,
} from './commands';

type Props = {
  content: string;
  setContent: (text: string) => void;
};

const MarkdownEditor = ({ content, setContent }: Props) => {
  return (
    <div className="markdown_container">
      <MDEditor
        height={'100%'}
        data-color-mode="light"
        visibleDragbar={false}
        commands={[
          commands.group(
            [commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6],
            {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
              icon: <HeadingIcon />,
            },
          ),
          customBoldCommand,
          customItalicCommand,
          customQuoteCommand,
          customCodeCommand,
          customLinkCommand,
          commands.divider,
          customOrderedListCommand,
          customUnorderedListCommand,
          customCheckedListCommand,
        ]}
        extraCommands={[]}
        preview="edit"
        value={content}
        onChange={(val) => setContent(val ?? '')}
      />
    </div>
  );
};

export default MarkdownEditor;
