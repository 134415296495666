import authMessages from '@/messages/auth';
import { Button, FormControl, Input, Stack, Text, Link as ChakraLink, VStack } from '@chakra-ui/react';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import authRoutes from '@/constants/routes/auth';
import { useForgotPassword } from '@/hooks/auth/resetPassword';
import { EMAIL_REGEX } from '@/constants/stringVars';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import appMessages from '@/messages';

type FormData = {
  email: string;
};

const schema = yup
  .object({
    email: yup.string().matches(EMAIL_REGEX, 'Please enter valid email address.').required('This field is required.'),
  })
  .required();

const ForgotPassword = () => {
  const { mutate: handleForgotPassword, isPending } = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  return (
    <AppPageTemplate>
      <VStack height={'100vh'} justifyContent={'center'}>
        <Text variant={'loraTitle'} marginBottom={'20px'}>
          {appMessages.forgotPassword}
        </Text>
        <Text
          variant={'urbanistSemiBold'}
          color={'text.darkBlue'}
          opacity={0.7}
          position={'relative'}
          marginTop={'20px'}
          maxWidth={'400px'}
        >
          {authMessages.forgotPwDescriptionOne}
        </Text>
        <Text
          variant={'urbanistSemiBold'}
          color={'text.darkBlue'}
          opacity={0.7}
          position={'relative'}
          marginTop={'10px'}
          maxWidth={'400px'}
        >
          {authMessages.forgotPwDescriptionTwo}
        </Text>
        <form onSubmit={handleSubmit((data) => handleForgotPassword(data.email))}>
          <Stack spacing={2} marginTop={'36px'} alignItems={'center'}>
            <FormControl isInvalid={!!errors.email}>
              <Input className="custom-input" {...register('email')} placeholder={authMessages.email} />
              <Text color={'extra.red'}>{errors.email?.message}</Text>
            </FormControl>
            <Button
              type="submit"
              variant="formSubmit"
              width="full"
              isLoading={isPending}
              isDisabled={!isValid}
              disabled={!isValid}
              marginTop={'40px'}
            >
              {authMessages.submit}
            </Button>
            <ChakraLink
              as={Link}
              to={authRoutes.signIn}
              fontSize={'14px'}
              marginTop={'30px'}
              color={'text.mediumBlue'}
              fontWeight={'700'}
              lineHeight={'18px'}
            >
              {authMessages.goBack}
            </ChakraLink>
          </Stack>
        </form>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/forgot-password/')({
  component: ForgotPassword,
});
