export const privacyPolicyMarkdownMock = `**Privacy, Data Use and Retention Policy**  [Privacy Policy (Previdence Corporation)](https://www.regain.us/privacy/)  (this needs to be a link??)

**1. What is this policy?**

This policy is intended to help you understand what data we collect, how we use it, and your rights related to it. For purposes of this policy and unless otherwise specified, “data” includes data that is linked to one person or household including things like name, email address, phone numbers, device ID, Third-Party identifiers, contact information, communications with therapists using our digital communication Platform (the “Platform”) to provide services (“therapists”), and IP address. Some jurisdictions might consider this to be “personal data,” “personally identifiable information,” or “sensitive personal data” in certain circumstances. When you use and access our app or website, you accept and agree to both the [Terms and Conditions](https://www.regain.us/terms/) and this Privacy Policy, including that we’ll share certain data with service providers.

The purpose of this Policy is to explain the technical aspects of data Processing in a simple and clear way. Please feel free to email support@previdence.com if you have any questions about this Policy, or any suggestions for us to improve it.

**2. Who does this apply to?**

This policy applies to any visitors to the public portions of our websites and apps, users of the paid portions of our Platform, and independent contractor therapists who are using the Platform to deliver therapy.

This policy does not apply to the collection and use of information for employees or independent contractors (not including therapists) of Previdence Corporation or its parent company. If you’re a current or former Previdence Corporation job applicant, employee, owner, director, officer, or independent contractor other than therapist, please contact us at support@previdence.com for the appropriate notice governing those portions of the Platform.

**3. When you use the term “Service Provider” in this Policy, what do you mean?**

We define a Service Provider as a person or company that we have a legal agreement with to [Process](https://www.regain.us/privacy/#section6) data collected by us or on our behalf. Data that is Processed on our behalf is required to be done only at our direction; no other person or company can authorize it. Our Service Providers are not permitted to disclose data that is individually identifiable to any other person or company, other than to us or the Service Providers’ own subcontractors (provided that they’re bound to data Processing terms that are no less restrictive than the terms the Service Provider is bound by).

The data obtained by Service Providers from their relationship with us must only be used for performing the services specified in our agreement with them, or as reasonably necessary to perform one or more of the following:

- Comply with applicable law, regulation, or legal process.
- detect, prevent, or mitigate fraud or security vulnerabilities.
- debug to identify and repair errors impairing existing intended functionalities.
- conduct internal research for technological development and demonstration of our products or services, if such use is reasonably necessary and proportionate to achieve the purpose for which the data was shared

**4. When you use the term “Third-Party” what do you mean?**

For the purposes of this policy, a Third-Party means an entity that is not a [Service Provider](https://www.regain.us/privacy/#section3), not a therapist or any other parties that are specifically called out in this Policy.

**5. Do you collect, store, or process my data?**

In this policy, we refer generally to activities done with data as “Processing.” Examples of Processing include collecting, storing, and using data. The categories of data which we Process are listed below. We Process this data to do things like operate the Platform and make sure you’re able to use our services effectively. We may also Process data to send you periodic emails or text messages. You can opt out of receiving texts or marketing communications at any time. Additionally, provided you opt-in and agree, we may Process and share some data with Third Parties for advertising purposes. You can find more details in the relevant sections of this policy.

**6. What specific data are you Processing?**

What data we Process depends on how you’re using our website, app, or the Platform. We explain below the specific data we Process. In the next section, we explain the purposes for Processing this data.

**Name of category of data**

**Information that is collected**

“Visitor data”

When you visit the website, app, or Platform, we Process information like the particular pages visited or which features you interacted with, the amount of time on the website or app, site/app/Platform errors, information about the type of device and browser you’re using, and IP address. We may Process your Third-Party identifier or advertising ID (if available based on the settings of your device) and will share it if you opt-in.

“Onboarding data”

When an account is created with the Platform, the user fills out a questionnaire. We Process the information used to complete this questionnaire. Some of this information may include identifiers like email address, phone number, chosen name (first name or nickname), and physical address.

“Account Registration Data”

Once a user registers with the Platform, we Process data such as the account name the user selects, the email that they use, their age, phone number, emergency contact details, and whether a user verifies their email address.

We also assign each user (including therapists) who creates an account a sequentially generated user ID.

“Transaction Data”

We Process data about payment transactions on the Platform such as whether a user completed payment for our services, signed up for services using a trial offer, canceled or ended a trial, received a discount or financial aid, or received any extensions or refunds.

“Member Engagement Data”

We Process data for logging into the Platform and activity conducted during that log in such as when a user logs in, the timing, number and length of messages received or sent through the Platform, the timing, number and duration of live session scheduled or conducted, the number and timing of use of other features such as worksheets, journals and goals. This category does not include Therapy Communications Data like the content of any messages sent or received by users.

“Checkout Flow Data”

We Process data about whether a user registers for the Platform or pays for access to the Platform services.

“Therapist Data”

In order to follow up with you on the status of your application, to identify, match, credential, re-credential, run checks, issue 1099s and pay therapists, we Process therapist information such as the therapist’s name, bank account information, gender, date of birth, governmental identification numbers (SSN/FEIN), e-mail address, phone number, address, NPI number (if applicable), license information, CAQH number (if applicable), and areas of interest/expertise, education, and job history. Therapists may also separately and outside of this policy, consent to using facial scans to assist logging them in and verifying their identity.

“Therapy Quality Data”

We Process client feedback about their therapist, ratings and reviews of their therapist, actions regarding switching therapist or quitting therapy, and the reason selected by the client. We Process therapist session availability.

“Therapist Engagement Data”

We Process data about therapist logins to the Platform, the number of live sessions conducted by a therapist, number of messages and words exchanged by a therapist, number of worksheets shared by a therapist, and number of journal entries shared with a therapist.

“Therapy Communications Data”

We Process communications and related information users share with their therapist to facilitate the therapy. This includes messages with therapists, worksheets, attendance, and journal entries.

“Customer Service Communications Data”

We Process communications users have with our customer service team.

**7. Why do you collect and process my data?**

There are a few reasons that we Process your data. We’ve tried to provide some examples that would be of most interest to you but please contact us at support@previdence.com if there are other things you’re interested in.

*We Process some data to connect you with therapy services*: In order for us to connect you with therapy services on our Platform, we need to be able to facilitate information sharing between you and your therapist so that you can get the help you need from them. We also Process data like your therapist preferences, your state (if applicable), and your country to determine what therapist to suggest to you based on applicable licensing requirements.

*We Process some data to communicate with you, verify your identity and secure your account*: For example, we need to make sure that if you ask a question or have a concern about the Platform, we’re able to respond to you and provide an answer.

*We Process some data to monitor and improve therapy quality*: For example, we track if a live session occurred, was canceled or if the therapist did not show up, to ensure that timely services are being delivered to you. We also track ratings, reviews, complaints, and other client feedback to ensure the quality of therapists on our Platform. If you consent, a licensed therapist who is employed as part of the Previdence Corporation Clinical Operations Team may review correspondence with your therapist for quality assurance purposes. For example, if you raise a concern about your therapist, or we have concerns about a specific therapist’s clinical care.

*We Process some data to monitor Platform usage for product improvements*: For example, we may track regular usage of certain pages, buttons, or features on the Platform in order to allocate our R&amp;D resources appropriately. In addition, if you opt-in to "performance cookies," we may process some data to track site visits across our sister sites such as Previdence and Teen Counseling. This is known as "first-party cross domain tracking".

*We Process some data to personalize your web or app experience*: For example, if you identify as a religious person and would prefer a therapist who utilizes faith-based practices, we Process that information to allow us to match you with an appropriate therapist that can meet these needs. Another example is if you state that you would like help with anxiety, we may recommend content and features that would be helpful to you, such as anxiety-related group sessions.

*We Process some data to offer you new features and make the services more convenient for you*: For example, we may use some of your data to determine which products and features to roll out to you. We may also Process data to know when you have already seen a “pop-up” on the Platform and do not need to be shown it anymore. Another example of this is when we use your IP address to help conveniently auto-populate your state (if applicable) and country or assist you with providing your address when completing information about your emergency contact.

*We Process some data to comply with laws*: For example, a court might subpoena information from us where we would be required to share certain information requested in the subpoena. This is not unique to Previdence and is applicable to in-person therapy as well. Keep in mind that, as a general rule, we defer to your chosen therapist to decide to produce (or not produce) any psychotherapy notes or messages you have had with them. Many jurisdictions have strict rules governing therapist/client relationships and the confidentiality requirements associated with that. We encourage you to discuss with your therapist early on if you have concerns about their disclosure obligations.

*We Process some data to protect your safety and the safety of others*: For example, if we have reason to believe that you or any other person may be in immediate danger or your privacy infringed upon, we may use the information to investigate or reach out to you or the appropriate authorities if it is legally appropriate/permitted to do so.

*We Process some data to monitor and protect the security of the Platform*: For example, we may process some of your data when issuing security patches and bug fixes in order to address security risks. We may also process your data to track potential abuse on the Platform, prevent and detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.

*We Process some data to help us understand how you use our service and improve it*: For example, we might assess the usage of various features and decide to invest more resources in features that are popular and/or decide to remove features that aren’t providing value to our members. We might also ask you for feedback about how we can improve our Platform or what you like or don’t like about the therapist that is suited to you.

*If you’re a therapist on our Platform, in addition to the above, we may Process some data to*:

- Assist in the therapist recruitment process and onboard you to the Platform.
- Operate the Platform, match clients to you based on your preferences, and facilitate communication between you and your clients.
- Verify your identity and secure your account.
- Run background checks and other screening required for credentialing and re-credentialing purposes.
- To pay you and comply with all relevant tax laws.
- Offer you information about new features, opportunities, perks, and other incentives.
- Promote your profile on Previdence Corporation and with Third-Party websites and directories to get you more clients (to opt-out email support@previdence.com).
- Send you email, calls or SMS reminders, notifications &amp; updates about your application, profile, or account.

*We Process some data to send you opportunities, promotions, news, updates and reminders about our services and your account*: For example, we might email you to offer you special promotions or discounts. We might also email you to provide you with therapy related news or content that you might find interesting. You can opt out of receiving texts or marketing communications at any time.

*If you opt in to targeting cookies and web beacons, we Process some data for advertising purposes*: To learn more, see section, [“Are you using my data for advertising?”](https://www.regain.us/privacy/#section11).

Please note that we may also transfer your personal information outside the EEA, or the UK as described in this policy.

**8. Who can see the interactions I have with my therapist?**

You and your therapist are able to see the messages you send, the worksheets you submit or the journal entries you submit (if you opt in to sharing journal entries).

If you consent, a licensed therapist who is employed as part of the Previdence Clinical Operations Team may review correspondence with your therapist for quality assurance purposes. For example, if you raise a concern about your therapist, or we have concerns about a specific therapist’s clinical care.

In addition, our internal Legal or Trust and Safety teams may review correspondence for specific accounts if we have a reason to believe that there is a security, legal, fraud or abuse issue occurring on that specific account.

Messages with your therapist are not shared with any Third-Party. We also do not share when you send a message, or have a session with your therapist, with any Third-Party.

**9. What are the purposes for sharing my data?**

Here’s some more information about the purposes for which we share your data:

- Your data may be shared to comply with applicable laws. For example, a court might subpoena information from us where we would be required to share certain information requested in the subpoena. This is not unique to Previdence and is applicable to in-person therapy as well. Keep in mind that, as a general rule, we defer to your chosen therapist to decide to produce (or not produce) any psychotherapy notes or messages you have had with them. Many jurisdictions have strict rules governing therapist/client relationships and the confidentiality requirements associated with that. We encourage you to discuss with your therapist early on if you have concerns about their disclosure obligations.
- Occasionally, your data may be handled by a select number of employees who are part of our parent company for support services. These employees are under strict duties of confidentiality. For example, a paralegal at our parent company may receive subpoenas or legal correspondence on behalf of members or former members (sometimes others too) and make sure they are provided to Previdence Corporation’s Legal team to address.
- We may share certain data with Service Providers that provide limited services that help us operate the Platform. Examples include:

<!--THE END-->

- - Data hosting and storage providers: For example, cloud hosting providers such as Amazon Web Services, or Azure.
  - Technology Service Providers: For example, we sometimes integrate tools into our Platform which gives our Platform more functionality, like technology that helps us host group meetings.
  - Customer Service Providers: For example, we use a tool that helps keep track of requests and questions from our members, visitors, and therapists in a secure way.
  - Email management and communication Service Providers: For example, we may use a tool that makes reaching out to you easier for us and more convenient for you.
  - Reporting and analytics Service Providers: For example, we might use a service to help us keep track of which pages and features are most used on our site.
  - Advisors and lawyers: To assist with business matters.

<!--THE END-->

- We may share some of your data with Service Providers to ensure the safety and security of the Platform and that of our users.
- We may share some of your data in connection with an asset sale, merger, or bankruptcy.
- We also use a few APIs and SDKs to improve your experience. The location you share while these tools are running may be read, accessed, and collected by that site in accordance with their Privacy Policy. For example, we use Google Maps API to allow your address to prepopulate for convenience as you are filling out forms. Google Maps features and content is subject to the then-current versions of the:

<!--THE END-->

- - Google Maps/Google Earth Additional Terms of Service at [https://maps.google.com/help/terms\_maps.html](https://maps.google.com/help/terms_maps.html)
  - Google Privacy Policy at [https://www.google.com/policies/privacy/](https://www.google.com/policies/privacy/).

Note that if you make any information publicly available on the Platform, such as with a public post, anyone may see and use such information.

If you opt-in to [“performance cookies”](https://www.regain.us/privacy/#section12), we may use Third-Party analytics cookies from trusted partners to Process data for activities including but not limited to analyzing traffic sources, visits, and site interactions. This analysis helps us to improve our products and services.

If you opt-in to [“targeting cookies”](https://www.regain.us/privacy/#section12) and web beacons, information regarding your activity on our websites, excluding activity when you’re logged in and have started therapy, may be shared for advertising purposes. To learn more, see section: [“Are you using my data for advertising?”](https://www.regain.us/privacy/#section11)

**10. Do you Process location data?**

We Process your address information when you provide it as part of your emergency contact information when you start therapy on the Platform. Your contact information is required to comply with therapy regulations and ethical code. It can be used, for example, in case your therapist believes you are in immediate danger.

Additionally, we Process IP addresses which provide a rough location allowing us to auto complete some of the address fields mentioned above for your convenience. We do this in our app and on our site through the assistance of the Google Maps API. The location you share while these tools are running may be read, accessed, and collected by that site in accordance with their Privacy Policy. Google Maps features and content is subject to the then-current versions of the:

- Google Maps/Google Earth Additional Terms of Service at [https://maps.google.com/help/terms\_maps.html](https://maps.google.com/help/terms_maps.html)
- Google Privacy Policy at [https://www.google.com/policies/privacy/](https://www.google.com/policies/privacy/)

IP address is also Processed by the ReCAPTCHA security API tool we use. We use ReCAPTCHA to identify potentially malicious actors trying to access our site. Here is the ReCAPTCHA Privacy Policy and terms of service.

To learn about the additional purposes for which we Process IP addresses, please see the following Sections:

- [Why do you collect and Process my data?](https://www.regain.us/privacy/#section7)
- [Are you using my data for advertising?](https://www.regain.us/privacy/#section11)
- [Additional privacy notice for Utah residents](https://www.regain.us/privacy/#section23)

**11. Are you using my data for advertising?**

In order to reach people who may be looking for mental health support, we advertise on some Third-Party web properties (for example, Third-Party websites and apps). In order to minimize advertising costs related to this process and downstream costs to you, we strive to deliver ads that are relevant, interesting, and personal.

Therefore, if you opt in to targeting cookies and web beacons, your IP address, Third-Party identifier (if applicable), [Checkout Flow Data](https://www.regain.us/privacy/#section6) (if applicable) and/or some [Visitor Data](https://www.regain.us/privacy/#section6) (like the fact of you “viewing a page”), excluding activity when you’re logged in and have started therapy, may be shared for advertising purposes. As a result, you may see ads for our services on some Third-Party websites.

To be clear, we don’t share any data or information you share with your therapist with any Third-Party advertisers. Even if you opt-in to targeting cookies and web beacons, we still don’t share information with Third-Party advertisers like member names, email addresses, phone numbers, clinician diagnosis, questionnaires answers, sessions data, journal entries, messages, worksheets, or any other type of private communication you have with your therapist on the Platform.

**12. What is a cookie or web beacon?**

A “cookie” is a small data file that is accessible within a folder on a computer, and it is used for record-keeping purposes. Cookies are used to enhance the performance of the Platform and for Third-Party tracking (as described above). For example, cookies may be used to help you quickly log into certain Platforms and websites without having to enter your credentials every time.

A “web beacon” or “pixel” is a tiny and sometimes invisible image or embedded code, placed on a web page or email that can report your visit or use to a Third-Party (again, as described above). In general, these tools can be used to monitor the activity of users for the purpose of web analytics, advertising optimization, or page tagging.

**13. What are you using cookies and web beacons for?**

We use our own and Third-Party cookies and web beacons to deliver a faster and safer experience, to monitor and analyze usage, to comply with laws, and for advertising purposes. To read more about the kinds of cookies we use and their purposes, or update your settings or opt out, go to "Cookie Settings".

**14. How do I opt out of cookies and web beacons?**

Please [visit our opt-out instructions page](https://www.regain.us/opt_out/) to opt-out of tracking via cookies or web beacons, or for instructions on how to remove previously set cookies.

**15. How do you keep my data secure?**

We apply industry standards and strive to apply best practices to prevent any unauthorized access and disclosure. Internet-based services carry inherent security risks, but our systems infrastructure, encryption technology, operation and processes are all designed, built, and maintained with your security and privacy in mind.

Previdence Corporation has an experienced team of data security professionals whose job is to make sure we use secure technology to protect your data. We have security staff who test internal security at Previdence Corporation to try and anticipate threat actors and security staff that act defensively and build processes and infrastructure to prevent incidents and attacks. We have numerous robust security practices such as:

- All the messages between a member and their therapist are secure and encrypted by 256-bit encryption.
- Our servers are distributed across multiple Tier 3 - AWS Data Centers for optimal security and protection.
- Our browsing encryption system (SSL) follows modern best practices.
- Our databases are encrypted and scrambled rendering them useless in the unlikely event that they are stolen or inappropriately retrieved.
- We have robust monitoring and alerting systems and procedures in place that include both automated systems and humans.

For your own security, keep the following in mind:

- Phishing: This is a type of online identity theft or account hacking. We will never request your login information or credit card information in any non-secure or unsolicited communication. You should always be diligent when you are asked to provide your account information and make sure it is in our secure system.
- Third-Party links: Our Platform may contain links to a Third-Party website or service. We do not control Third-Party websites, and do not have control over their privacy policies and terms of use. The fact that we link to a website is not an endorsement, authorization, or representation of our affiliation with that Third-Party or of their privacy and security policies or practices.

**16. Do you sell my data?**

We aren’t paid by anyone for any data. However, in Utah, the laws define “sale” broadly to include the sharing of personal information in exchange for *anything of value*. If you opt into our use of targeting cookies and web beacons, this use *may* be considered a “sale” of personal information under that specific Utah law. For specific information on your data rights as a resident of Utah, see the [additional notice for Utah residents](https://www.regain.us/privacy/#section23).

**17. Can I sign up for Previdence and remain anonymous?**

You will need to provide your name and an email address so that we can verify you, keep your account secure and so we can communicate with you. You can choose an email that does not include your name, but you should be aware that in some jurisdiction’s emails may be “personal data,” “personally identifiable information” or “sensitive personal data” in certain circumstances. When you decide to start the therapy process, we’ll ask you for your contact information for emergency situations. Your therapist may request additional specific information about you as required by their licensing board guidelines.

Even though we try to limit the kinds of information you must provide to us as discussed above, it is very difficult to be truly “anonymous” when you use any app or the internet. Read more about what data we Process and why here:

- [What specific data are you Processing?](https://www.regain.us/privacy/#section6)
- [Do you collect or Process location data?](https://www.regain.us/privacy/#section5)
- [Why do you collect and Process my data?](https://www.regain.us/privacy/#section7)
- [What are you using cookies and web beacons for?](https://www.regain.us/privacy/#section13)

If you’re interested in further limiting what data is Processed, visit [our opt-out instructions page](https://www.regain.us/opt_out/) to opt-out of tracking via cookies or web beacons, or for instructions on how to remove previously set cookies.

**18. How do I request my data or delete it?**

To receive a summary copy of your data, please log in to your account and go to Menu &gt; My Account (or Account settings) &gt; My Personal information, where you will see an option to request a copy of your data. The data you will receive as part of this request includes the contact information that you input on the site, questionnaire answers, worksheet entries, emergency contact information, messages you sent to your therapist, journal entries that you created, and other personal data.

Additional data which we maintain includes email interactions with our help desk, which is stored on your email system. You may also request this information by writing to support@previdence.com.

Please visit our [opt-out instructions page](https://www.regain.us/opt_out/) to request to delete the above data or opt out of Previdence services. Some data will be maintained as allowed by law or required by our retention policy.

You may reach out to us at support@previdence.com if you need additional help. We will only comply with a request for deletion of your data if we can verify your identity. There is usually no charge. In exceptional circumstances, we may charge a reasonable fee after discussing the fee with you.

Please note that if you’ve started therapy, we may retain some of your data for an extended period based on applicable data retention laws.

Requirements:

- Only you or your authorized representative may make a request on your behalf. You may also make a request on behalf of your minor child depending on the applicable laws.
- We reserve the right to deny information requests that are unduly burdensome as allowed by law.
- You must provide sufficient information that allows us to reasonably verify your identity or status as an authorized representative.
- You must provide details that allow us to understand, evaluate, and respond to your request.

If you have any questions about your rights, want to exercise them, or want to appeal our response to your request, please contact us in any of the following ways:

Previdence Corporation

5742 South 1475 East, St. 200

South Ogden, UT 84403, USA

support@previdence.com

By calling our number: (801) 409-0904

We will not discriminate against you for exercising any of your privacy rights. We will not deny you services, charge you different prices or rates, impose penalties, or provide a different quality of service.

**19. How can I stop receiving direct marketing emails from you?**

You can always opt out of receiving marketing emails. In order to opt out, you can select the unsubscribe link located at the bottom of the relevant email communication.

**20. How do you treat data from children?**

We don’t knowingly collect or solicit any data or information from anyone under the age of 13 or knowingly allow such persons to become our users, unless we have written authorization from parent or legal guardians.

**21. How do you use my data to comply with the law?**

Where we are required by law, we cooperate with government agencies. This is not unique to Previdence Corporation and is applicable to in-person therapy as well. For example, a court might subpoena information from us where we would be required to share certain information requested in the subpoena. Keep in mind that, as a general rule, we defer to your chosen therapist to decide to produce (or not produce) any psychotherapy notes or messages you have had with them. Many jurisdictions have strict rules governing therapist/client relationships and the confidentiality requirements associated with that. We encourage you to discuss with your therapist early on if you have concerns about their disclosure obligations.

You should also be aware that therapists may be obliged to disclose information to authorities to meet professional and legal responsibilities. Specifically, some laws require mental health professionals to disclose information and/or take action for: (a) reported or suspected abuse; (b) serious suicidal potential; (c) threatened harm; and (d) court-ordered treatment. You should speak with your therapist if you have concerns about this.

**22. Will you change this Privacy Policy?**

We may update this Privacy Policy. When we make significant changes to this policy, we will notify you through our website or app when you log in to your account. We encourage you to periodically review this page for the latest information.

**23. Additional privacy notice for Utah residents**

This **Privacy Notice for Utah Residents** supplements the Previdence Privacy Policy to comply with the Utah Consumer Privacy Act of 2018 ("CCPA") and the Utah Privacy Rights Act ("CPRA") of 2023.

The CCPA and the CPRA are Utah laws that provide its residents with certain rights over information about them, including notice about the categories of personal information we have collected from them in the preceding 12 months and the purposes for which the information is used or disclosed, and correction of personal information.

The following Sections outline the data that is Processed by us, as well as the purpose for collection, and the categories of sources of such information:

- [What specific data are you Processing?](https://www.regain.us/privacy/#section6)
- [Do you collect or Process location data?](https://www.regain.us/privacy/#section5)
- [Why do you collect and Process my data?](https://www.regain.us/privacy/#section7)
- [What are you using cookies and web beacons for?](https://www.regain.us/privacy/#section13)

The data referenced at those links may fall in certain defined categories under the CCPA and CPRA. Accordingly, we may have collected:

- Identifiers
- Personal information categories listed in the Utah Customer Records statute (UT. Civ. Code § 1798.80(e))
- Protected classification characteristics under Utah or federal law
- Commercial information
- Biometric information
- Internet or other similar network activity
- Geolocation data
- Sensory data
- Sensitive Personal Information
- Professional or employment-related information
- Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))

The information that we have disclosed in the past 12 months and the recipients of the information are described above, in the Section titled [“What are the purposes for sharing my data?”](https://www.regain.us/privacy/#section9) The information that we may have shared in the past 12 months falls into the following personal information categories under the CCPA and CPRA:

- Identifiers
- Personal information categories listed in the Utah Customer Records statute (UT. Civ. Code § 1798.80(e))
- Protected classification characteristics under Utah or federal law
- Commercial information
- Internet or other similar network activity
- Geolocation data
- Sensory data
- Sensitive Personal Information
- Professional or employment-related information
- Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))

As noted in the Section titled [“Do you sell my data?”](https://www.regain.us/privacy/#section16), our “sale” of information (including sale of information about consumers under the age of 16) consists of the disclosure of your information for targeted advertising purposes, and we aren’t paid by any Third-Party for any data. The information that we may have “sold” (for purposes of the CCPA and CPRA) in the past 12 months falls into the following personal information categories under the CCPA and CPRA:

- Identifiers
- Commercial information
- Internet or other similar network activity

**Do I have the right to know what information you have about me?**

Yes, as a Utah resident you can request certain information about what we have Processed over the past 12 months. Once we receive and verify your consumer request, we can provide:

- The categories of personal information we collected about you.
- The categories of sources for the personal information we collected about you.
- Our business or commercial purpose for collecting that personal information.
- The categories of Third Parties with whom we shared that personal information.
- The specific pieces of personal information we collected about you.
- Whether we disclosed your personal information for a business purpose and the personal information categories that each category of recipient obtained.

We will verify your identity by matching the information you provide with information that we maintain about you or via biometrics (specifically, FaceID via iOs). You also have the right to request that we correct personal information about you if it is found to be inaccurate. To make such a request, please send an email to support@previdence.com

**Can I “opt out” or request that you delete my information?**

Yes, you can request that we delete your data as described in the section of this policy called: ["How do I request my data or delete it?"](https://www.regain.us/privacy/#section18) Once your request is received and verified (by matching the information you provide with information that we maintain about you or via iOs FaceID), we’ll move forward with the Process of deleting your information in line with our legal requirements and retention policy. We cannot fulfill a deletion request and need to retain your information if the data is necessary to:

- Provide you services, take actions reasonably anticipated within the context of our ongoing business relationship, or otherwise perform our contract with you.
- Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
- Debug products to identify and repair errors that impair existing intended functionality.
- Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
- Comply with applicable laws, including but not limited to, the Utah Electronic Communications Privacy Act (UT. Penal Code § 1546 seq.)??? and information covered by the Utah Confidentiality of Medical Information Act.
- Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.
- Make other internal and lawful uses of that information that are compatible with the context in which you provided it.

As noted above, you may opt out of the “sale” of personal information about you by withdrawing your consent to accept cookies used for advertising [here](https://www.regain.us/opt_out/). Our websites are also designed to implement a do-not-sell privacy preference requested via a global privacy control.

**Other Utah privacy rights**

Utah’s (Civil Code Section § 1798.83) permits Utah residents to request certain information regarding our disclosure of personal information to Third Parties for direct marketing purposes. To make such a request, please send an email to support@previdence.com

**24. General Data Protection Regulation (GDPR) and UK General Data Protection Regulation Notice**

This section provides additional information about our Policy relevant to you if you are from the European Economic Area (the EEA), United Kingdom, and Switzerland (together “European Area Countries”). It supplements and should be read in conjunction with the rest of the Policy.

Under the European Area Countries’ privacy laws, we are the Controller with respect to your data.

**When is my data used?**

- When it is in our legitimate interests or a Third-Party ’s legitimate interests (“legitimate interest” is a term defined by the General Data Protection Regulation (GDPR) and UK General Data Protection Regulation Notice). Our legitimate interests in this instance include managing the Platform and Previdence Corporation business, safety and security of the infrastructure, prevention of fraud, research and development, and management of contracts and legal claims.
- When it is needed for the provision of the Platform. In particular, for product development and internal analytics purposes, and otherwise to improve the safety, security, and performance of the Platform. We only rely on our or a Third-Party ’s legitimate interests to Process your data when these interests are not overridden by your rights and interests.
- When it is necessary to do so to comply with any legal obligations imposed upon us, for example under our contractual obligation or applicable law.
- In rare instances, when it is a medical emergency, we may use your data to protect your or another’s vital interests if consent is not a reasonable option.
- When you have consented to the use of your data, for example for marketing purposes or through the use of cookies and web beacons. Where consent is the legal basis, you have the right to withdraw your consent at any time.

**What Lawful Basis for Sensitive Data is Used in the UK and EEA?**

About sensitive personal information:

Previdence Corporation may also collect and Process certain categories of personal information, which may be considered “sensitive personal information” in the UK and EEA. The lawful bases for this Processing are (1) health and social care, (2) our establishment, exercise, or defense of a right or legal obligation, (3) substantial public interest, and (4) consent. Where consent is the legal basis, you have the right to withdraw your consent at any time. Sensitive personal information that we Process includes your racial or ethnic origin, religious or philosophical beliefs, and data concerning your health or about your sex life or sexual orientation.

When you begin to use our services and register your account, we ask you to provide answers to a questionnaire to customize the service, to match you with the right counselor, and to provide therapy and related services to you. In providing your responses to the questionnaire you may provide us with “sensitive personal Information” as described above. You may also continue to share such data with us as you receive services. This data is necessary as it allows us to continue providing services to you and customize our services for you and is necessary to provide healthcare with a personalized and well selected therapist based on points of data which impact your therapy and health care needs. The therapist also reviews this data and can choose to not work with you if they are not a good fit. We may also use this information to improve our service and understand how you interact with the services.

**How we obtain your personal information**

Previdence Corporation obtains the categories of personal information listed above from the following sources:

- Directly from you, such as information when you apply to be a counselor or that you submit during the Process of using and paying for our Services.
- Indirectly from you, such as through your actions on our website.
- From Third-Party business partners, such as social media sites, ad networks, and analytics providers.

**What are my rights and choices under European Area Countries laws?**

European Area Country residents have specific rights regarding their data. This section describes your rights if you are resident in European Area Countries and explains how to exercise those rights.

- *Subject access request*: you may be entitled to ask us for a copy of any data which we hold. We will normally send you a copy within one month of your request. However, that period may be extended by two further months where necessary, taking into account the complexity of the request or the difficulty in accessing the data that you request. There is usually no charge. In exceptional circumstances, we may charge a reasonable fee after discussing the fee with you.
- *Right to rectification*: if the data we hold about you is inaccurate, you may request rectification. The data will be checked, and, where appropriate, inaccuracies will be rectified.
- *Right to erasure*: in certain circumstances, you may be entitled to ask us to erase your data.
- *Right to data portability*: in certain circumstances, you may wish to move, copy, or transfer the electronic data that we hold about you to another organization.
- *Right to object*: you may object to your data being used for direct marketing. You may object to the continued use of your data in any circumstances where we rely upon consent as the legal basis for Processing it. Where we rely upon legitimate interests as the legal basis for Processing your data, you may object to us continuing to Process your data, but you must give us specific reasons for objecting. We will consider the reasons you provide, but if we consider that there are compelling legitimate grounds for us to continue to Process your data, we may continue to do so. In that event, we will let you know the reasons for our decision. In some instances, objecting to certain Processing may impact our ability to provide you with services.
- *Rights related to automated decision-making including profiling*: we use limited data to operate the Platform and to carry out certain profiling activities to support and grow our business. When doing so, we rely upon our legitimate interests as the lawful basis for Processing your data, and you may exercise the above rights if you do not wish us to Process your data in this way.

To exercise the rights in relation to your data set out in this section, please contact us at support@previdence.com.

**Is my data transferred internationally?**

As a part of our standard business practices, we may transfer your data to organizations based in countries that have not been granted an adequacy decision under the General Data Protection Regulation. Where data is transferred to such countries, we shall ensure that specific safeguards or derogations have been established.

These might include where the data transfer is necessary in order to fulfill a contract between us and yourself, where we have received your specific consent after having made you aware of any risks involved, or where contracts are in place between us, and the Third Parties involved that ensure the recipient organization has a suitable standard of data protection in place.

You can contact our Data Protection Officer with questions, about this policy, or about your data by writing to:

Previdence Corporation

5742 South 1475 East, St. 200

S. Ogden, UT 84403, USA

support@previdence.com

By calling out number: (801) 801-409-0904

While we’ll always work with you to resolve any concerns you have about the use of your data, under GDPR you have the right to lodge a complaint with the supervisory authority in your country of residence if you have any concerns about our use of your personal information.

**Last Updated and Effective: July 10, 2023**

 

**Our commitment to Accessibility**                      [Web Accessibility |Previdence](https://www.regain.us/accessibility/)

Previdence Corporation utilizes various technologies that are meant to make our website reasonably accessible. By visiting this page our accessibility features have been enabled.

**Enabling accessibility on Desktop Browser:**

- To launch the accessibility preferences interface at any time, press the Alt+9 keyboard combination or by clicking on the icon that appears on the corner of the page.
- To turn on screen-reader adjustments at any time, press the Alt+1 keyboard combination.
- To turn off keyboard navigation at any time, press the Alt+8 keyboard combination.

Please disable any ad-blocker software to ensure that this interface functions properly.

**Accessibility for mobile users:**

For mobile users, we recommend using your device's native accessibility features. Instructions for iPhone can be found [here](https://www.apple.com/accessibility/iphone/), and for Android can be found [here](https://support.google.com/accessibility/android/answer/6006564?hl=en).

**Commitment and Standards**

We are actively striving to provide equal access and opportunity for individuals with any disabilities and to deliver a web experience that is aligned with the most recent Web Content Accessibility Guidelines (WCAG) at the AA level, published by World Wide Web Consortium (W3C).

**Comments and Feedback**

We strive to adhere to the accepted guidelines and standards for reasonable accessibility and usability. If you experience any difficulty accessing our website, please contact us at support@previdence.com with any questions or concerns.`;
