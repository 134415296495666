export const primary = {
  600: '#3B10C4', // dark purple
  500: '#4E1AEF', // light purple, main purple
  400: '#835FF4',
  300: '#A68CF7',
  200: '#CABAFA',
  100: '#4E1AEF1A', // lightPink
};

export const secondary = {
  600: '#25B59B', // dark teal
  500: '#00D2AD', // teal
  400: '#62B5A6', // tealDisabled
  300: '#80E8D6',
  200: '#BFF4EB',
  100: '#A7F9EA', // lightTeal
};

export const background = {
  lightGray: '#F8FCFF',
  lightBlue: '#F0F8FF', // veryLightBlue
  lightPurple: '#F8F5FF',
  cloudGray: '#E3EEF8',
  darkGray: '#D2E1EE',
  gray: '#CCDDEB',
  blueGray: '#7C9EBC',
  separatorBlue: '#BFDAF1',
};

export const text = {
  darkBlue: '#002548',
  mediumBlue: '#004179',
  mediumGray: '#7C9EBC',
  veryLightBlue: '#F0F8FF',
};

export const extra = {
  black: '#000000',
  black50pct: '#00000080',
  white: '#FFFFFF',
  lightBlue: '#E3F2FE',
  lightBlue80pct: '#E3F2FEc0',
  pink: '#1B03AF80',
  red: '#D84545',
  darkRed: '#CC2020',
  orange: '#FF8811',
  violet: '#5F4DCE',
  lightViolet: '#8C80DC',
  waterBlue: '#005399',
  inActiveMenuOption: '#E4EFF8',
  whiteOptionsShadow: '#00417926',
};
