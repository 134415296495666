import { Text, useStyles } from "@chakra-ui/react";

interface Props {
  node: any;
}

const Paragraph = ({ node, ...props }: Props) => {
  const styles = useStyles();

  return <Text sx={styles.paragraph} {...props} />;
};

export default Paragraph;
