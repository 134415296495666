import React from 'react';
import { Input, Select, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import appRoutes from '@/constants/routes/app';
import { useCreateAssessment } from '@/hooks/assessment/assessment';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const CreateAssessmentModal = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useCreateAssessment((assessment) => {
    navigate({ to: `${appRoutes.assessments}/${assessment._id}` });

    queryClient.invalidateQueries({ queryKey: ['assessment'] });
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .min(6, 'Title needs to be at least 6 characters long.')
          .required('Title is required.'),
        type: yup.string().required('Type is required.'),
        slug: yup
          .string()
          .trim()
          .strict(true)
          .min(6, 'Slug needs to be at least 6 characters long.')
          .required('Slug is required.'),
      }),
    ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
    setValue,
  } = methods;

  const typeValue = watch('type') || `{type}`;
  const slugValue = watch('slug') || `{slug}`;

  const onSubmit = ({ title, type, slug }: { title: string; type: string; slug: string }) => {
    mutate({ title, type, slug });
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Create New Assessment" height={'40vh'} width="40%">
      <VStack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack spacing={'20px'} width={'100%'}>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Select placeholder="Select Assessment Type" {...register('type')}>
                  <option value={'ASSESSMENT'}>Assessment</option>
                  <option value={'SCREENER'}>Screener</option>
                </Select>
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.type?.message ?? ''}
                </Text>
              </VStack>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input placeholder="Title" {...register('title')} />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.title?.message ?? ''}
                </Text>
              </VStack>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input
                  placeholder="Custom Slug"
                  {...register('slug')}
                  onChange={(e) => {
                    const val = e.target.value;
                    const value = val.replaceAll(/\s/g, '-').toLowerCase();
                    setValue('slug', value, { shouldValidate: true });
                  }}
                />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.slug?.message ?? ''}
                </Text>
              </VStack>
              <Text>{`https://dev.therapist.myomnia.health/${typeValue?.toLowerCase()}/${slugValue}`}</Text>
              <CustomButton label="Create" buttonType="submit" disabled={!isValid} isLoading={isPending} />
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default CreateAssessmentModal;
