import { Flex } from '@chakra-ui/react';
import Sidebar from './Sidebar';

const SidebarContentWrapper = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <Flex w={'100%'}>
      <Sidebar />
      <Flex width={'100%'} marginTop="30px" marginLeft={'30px'} marginRight={'30px'}>
        {children}
      </Flex>
    </Flex>
  );
};

export default SidebarContentWrapper;
