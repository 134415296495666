import React from 'react';
import { Select, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import appRoutes from '@/constants/routes/app';
import { useCreateLegalDocument } from '@/hooks/legal/legal';
import { TLegalDocument } from '@/types/Legal.types';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const CreateLegalDocumentModal = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useCreateLegalDocument((document: TLegalDocument) => {
    navigate({ to: `${appRoutes.eula}/${document._id}` });
    queryClient.invalidateQueries({ queryKey: ['legal-documents'] });
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        type: yup.string().required('Type is required.'),
      }),
    ),
  });

  const onSubmit = async ({ type }: { type: string }) => {
    mutate({ type });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Create New Legal Document" width="25%" height="22vh">
      <VStack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack spacing={'20px'} width={'100%'}>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Select placeholder="Select New Legal Document Type" {...register('type')}>
                  <option disabled={true} value={'EULA'}>
                    EULA
                  </option>
                  <option value={'TERMS_AND_CONDITIONS'}>Terms & Conditions</option>
                  <option value={'PRIVACY_POLICY'}>Privacy Policy</option>
                  <option disabled={true} value={'HIPAA_DISCLOSURE'}>
                    HIPAA Disclosure
                  </option>
                  <option disabled={true} value={'DATA_USE_RETENTION'}>
                    Data Use Retention
                  </option>
                </Select>
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.type?.message ?? ''}
                </Text>
              </VStack>
              <CustomButton label="Create" buttonType="submit" disabled={!isValid} isLoading={isPending} />
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default CreateLegalDocumentModal;
