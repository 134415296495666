import { useMemo } from "react";

import template from "./template";

/**
 * Translates data that matches the interpolation pattern
 * @param {*} data - Whatever data to translate
 * @param {Object} options
 * @param {String} options.interpolate - Regex pattern to interpolate
 * @param {Func} options.valueGetter - Action to do when a pattern is matched
 * @returns The translated data
 */
const useTranslateData = (data = {}, options: any = {}) => {
  return useMemo(() => {
    const stringified = JSON.stringify(data);
    const formatter = template(stringified, {
      interpolate: options?.interpolate,
      valueGetter: options?.valueGetter,
    });
    const formatted = formatter({});
    const parsed = JSON.parse(formatted);

    return parsed;
  }, [data, options]);
};

export default useTranslateData;
