import {
  Heading as ChakraHeading,
  useStyles,
} from "@chakra-ui/react";

interface Props {
  node: any;
  level: number;
}

const Heading = ({ node, level, ...props }: Props) => {
  const styles = useStyles();
  const sizes = ["2xl", "xl", "lg", "md", "sm", "xs"];

  return (
    <ChakraHeading
      sx={styles.heading}
      {...props}
      as={`h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6"}
      size={sizes[`${level - 1}`]}
    />
  );
};

export default Heading;
