import { Center, Text, VStack } from '@chakra-ui/react';
import { getHighestUserRoleDisplay } from '@/utils/stringUtils';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import tableTheme from '@/theme/components/table';
import { TUser, TUserActivationToggle } from '@/types/User.types';

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { TPagination } from '@/services/api';
import LoadingPage from '@/components/templates/LoadingPage';
import { formatDistance } from 'date-fns';
import { INCONCLUSIVE_LAST_LOGIN_TEXT } from '@/constants/stringVars';

const COLUMNS: string[] = ['First Name', 'Last Name', 'Email', 'Role', 'Organization', 'Last Sign In', 'Activation'];

interface Props {
  users: TUser[] | undefined;
  pagination: TPagination;
  isLoading: boolean;
  isRefetching: boolean;
  ActionIcon: React.FunctionComponent<TUserActivationToggle>;
  invalidatedUserId?: string;
  isSearching?: boolean;
}

const UsersTable = ({
  users,
  pagination,
  isLoading,
  isRefetching,
  ActionIcon,
  invalidatedUserId,
  isSearching,
}: Props) => {
  // const router = useRouter();
  const theme = useTheme(tableTheme);

  const data = {
    nodes:
      users?.map((user) => ({
        ...user,
        id: user.user_id,
      })) ?? [],
  };

  // const onSelectChange = (action: { payload?: { id: string } }) => {
  //   const userId = action.payload?.id;
  //   router.navigate({ to: `/users/${userId}` });
  // };

  // const select = useRowSelect(
  //   { nodes: [] },
  //   {
  //     onChange: onSelectChange,
  //   },
  //   { isCarryForward: false },
  // );

  if (isLoading) return <LoadingPage />;

  return (
    <>
      {/* select={select} */}
      <Table data={data} theme={theme}>
        {(tableList: any) => (
          <>
            <Header>
              <HeaderRow>
                {COLUMNS.map((column: string) => (
                  <HeaderCell key={column}>{column}</HeaderCell>
                ))}
              </HeaderRow>
            </Header>

            {tableList.length === 0 && (
              <Center width={'75vw'} marginBottom={'50px'}>
                <Text variant={'loraSmallTitle'} marginTop={'70px'} marginLeft={'-150px'}>
                  No users
                </Text>
              </Center>
            )}

            {isSearching && (
              <VStack
                position={'absolute'}
                backgroundColor={'white'}
                top={'70px'} // to not animate the table header
                height={'100%'}
                width={'100%'}
                zIndex={1}
              >
                <Center>
                  <LoadingPage width="100%" height="70%" />
                </Center>
              </VStack>
            )}

            <Body>
              {tableList.map((item: (typeof data.nodes)[number]) => {
                const isInvalidatedUser = item.id === invalidatedUserId;
                return (
                  <Row key={item.id} item={item} className={isInvalidatedUser ? 'tr-invalidated' : ''}>
                    <Cell>{item.firstName}</Cell>
                    <Cell>{item.lastName}</Cell>
                    <Cell>{item.email}</Cell>
                    <Cell>{getHighestUserRoleDisplay(item.roles)}</Cell>
                    <Cell>{item.organizationName !== '' ? item.organizationName : '/'}</Cell>
                    <Cell>
                      {item.last_login
                        ? formatDistance(new Date(item.last_login), new Date(), { addSuffix: true })
                        : INCONCLUSIVE_LAST_LOGIN_TEXT}
                    </Cell>
                    <Cell>
                      {isInvalidatedUser ? (
                        ''
                      ) : (
                        <ActionIcon
                          id={item.id}
                          firstName={item.firstName}
                          lastName={item.lastName}
                          last_login={item.last_login}
                        />
                      )}
                    </Cell>
                  </Row>
                );
              })}
            </Body>
          </>
        )}
      </Table>

      {data.nodes.length > 0 && <CompactTablePagination pagination={pagination} isFetchingNextPage={isRefetching} />}
    </>
  );
};

export default UsersTable;
