import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import React, { CSSProperties } from 'react';

const SidebarItem = ({
  title,
  href,
  isSelected,
  onClick,
  hoverStyle = { backgroundColor: 'primary.lightPurple' },
}: {
  title: string;
  href: string;
  isSelected: boolean;
  onClick?: () => void;
  hoverStyle?: CSSProperties;
}) => {
  return (
    <ChakraLink to={href} as={Link} style={{ width: '100%' }} onClick={onClick} _hover={{ textDecoration: '' }}>
      <Flex
        cursor={'pointer'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={'38px'}
        // paddingY={"22px"}
        height={'54px'}
        textAlign={'center'}
        lineHeight={'19px'}
        fontWeight={isSelected ? 'bold' : 700}
        boxShadow={isSelected ? '0px 0px 20px 0px extra.whiteOptionsShadow' : 'none'}
        fontSize={'16px'}
        color={isSelected ? 'black' : 'background.blueGray'}
        bgColor={isSelected ? 'white' : 'extra.inActiveMenuOption'}
        _hover={{
          ...hoverStyle,
          bgColor: 'white',
          color: 'black',
          boxShadow: '0px 0px 20px 0px extra.whiteOptionsShadow',
        }}
        borderRadius={'8px'}
        transition={'all 0.3s'}
      >
        {title}
      </Flex>
    </ChakraLink>
  );
};

export default SidebarItem;
