import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import { hexToRGBA } from '../utils';

const purple = '#1A00B9';
const mainPurple = '#4E1AEF';
const red = '#D84545';

export default {
  Button: {
    defaultProps: {
      size: 'md',
      variant: 'default',
    },
    variants: {
      default: {
        minHeight: '20px',
        borderRadius: '8px',
        paddingY: '10px',
        paddingX: '20px',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: '900',
        background: 'white',
        textColor: '#000000',
      },
      outline: {
        color: '#FFFFFF',
        backgroundColor: 'transparent',
        borderWidth: '1px',
        borderColor: '#FFFFFF',
        fontSize: '14px',
        textColor: '#FFFFFF',
        _hover: {
          textColor: purple,
          borderColor: 'transparent',
          backgroundColor: '#FFFFFF',
          boxShadow: `0px 2px 4px ${hexToRGBA(purple, 0.5)}`,
        },
      },
      cta: {
        height: '53px',
        paddingY: '18px',
        paddingX: '25px',
        bg: '#FFFFFF',
        textColor: purple,
        boxShadow: `0px 2px 4px ${hexToRGBA(purple, 0.5)}`,
        backgroundColor: '#FFFFFF',
        _hover: {
          color: '#FFFFFF',
          backgroundColor: 'transparent',
          borderWidth: '1px',
          borderColor: '#FFFFFF',
          boxShadow: '0px 0px 0px 0px',
        },
      },
      submit: {
        // height: "42px",
        minWidth: '190px',
        paddingY: '18px',
        paddingX: '30px',
        borderRadius: '8px',
        boxShadow: `0px 2px 4px 0px "#00D2AD"`,
      },
      formSubmit: {
        height: '54px',
        minWidth: FORM_FIELD_WIDTH,
        paddingY: '18px',
        paddingX: '30px',
        borderRadius: '8px',
        colorScheme: 'secondary',
        backgroundColor: 'secondary.500',
        boxShadow: `0px 2px 4px ${hexToRGBA('#00D2AD', 0.5)}`,
        color: 'white',
        fontSize: '14px',
        fontWeight: '700',
        _hover: {
          backgroundColor: 'secondary.600',
          borderWidth: '1px',
          borderColor: 'secondary.500',
        },
        _disabled: {
          backgroundColor: 'secondary.400',
          borderWidth: '1px',
          borderColor: 'secondary.400',
          boxShadow: `0px 2px 4px ${hexToRGBA('#62B5A6', 0.5)}`,
        },
      },
      danger: {
        display: 'flex',
        justifyContent: 'center',
        color: red,
        fontWeight: '700',
        fontSize: '10px',
        px: '10px',
        border: '2px solid transparent',
        _hover: { borderColor: red },
        boxShadow: 'none !important',
      },
      continue: {
        height: '54px',
        width: FORM_FIELD_WIDTH,
        paddingY: '18px',
        paddingX: '30px',
        bg: mainPurple,
        textColor: '#FFFFFF',
        boxShadow: `0px 2px 4px ${hexToRGBA(mainPurple, 0.5)}`,
        backgroundColor: mainPurple,
        fontSize: '14px',
        fontWeight: '700',
        _hover: {
          color: 'white',
          backgroundColor: 'primary.600',
          borderWidth: '1px',
          borderColor: mainPurple,
          boxShadow: '0px 0px 0px 0px',
        },
      },
      headerLinkButton: {
        paddingY: '14px',
        paddingX: '30px',
        backgroundColor: 'extra.white',
        color: 'text.mediumGray',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: '700',
        minHeight: '54px',
        boxShadow: '0px 2px 4px 0px #7C9EBC80',
        borderRadius: '8px',
      },
    },
  },
};
