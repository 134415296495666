import React, { PropsWithChildren } from 'react';
import CustomButton from '@/components/atoms/CustomButton';
import { HStack, Text, VStack } from '@chakra-ui/react';

interface Props extends PropsWithChildren {
  title: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  customButton?: JSX.Element;
}

const SidebarItemViewTemplate = ({ children, title, buttonLabel, onButtonClick, customButton }: Props) => {
  return (
    <VStack width={'100%'}>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <Text variant="loraTitle">{title}</Text>
        {!!buttonLabel && <CustomButton label={buttonLabel} onClick={onButtonClick} />}
        {customButton}
      </HStack>
      {children}
    </VStack>
  );
};

export default SidebarItemViewTemplate;
