import { editor } from 'monaco-editor'

export const options: editor.IStandaloneEditorConstructionOptions = {
  renderLineHighlight: 'none',
  quickSuggestions: true,
  glyphMargin: false,
  lineDecorationsWidth: 0,
  folding: true,
  fixedOverflowWidgets: true,
  acceptSuggestionOnEnter: 'on',
  hover: {
    delay: 100,
  },
  roundedSelection: false,
  contextmenu: true,
  cursorStyle: 'line-thin',
  occurrencesHighlight: 'singleFile',
  links: true,
  minimap: { enabled: true },
  // see: https://github.com/microsoft/monaco-editor/issues/1746
  wordBasedSuggestions: 'allDocuments',
  
  // disable `Find`
  find: {
    addExtraSpaceOnTop: true,
    autoFindInSelection: 'never',
    seedSearchStringFromSelection: 'never',
  },
  fontSize: 12,
  fontWeight: 'normal',
  wordWrap: 'on',
  lineNumbers: 'on',
  // lineNumbersMinChars: 0,
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  hideCursorInOverviewRuler: true,
  scrollBeyondLastColumn: 0,
  scrollbar: {
    horizontal: 'hidden',
    vertical: 'hidden',
    // avoid can not scroll page when hover monaco
    alwaysConsumeMouseWheel: false,
  },
  autoIndent: 'advanced', 
  autoClosingBrackets: 'always', 
  formatOnType: true,
};
