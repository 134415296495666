import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { usePagination } from '../usePagination';
import { TOrganization } from '@/types/Organization.types';
import { TCustomAxiosError } from '@/services/api';
import useToast from '../useToast';
import {
  organizationByIdGet,
  inviteOrganizationAdminPost,
  getAllOrganizations,
  createOrganizationPost,
} from '@/services/api/requests/organization';

const useCreateOrganization = (
  onSuccess?: (organization: TOrganization) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<TOrganization, TCustomAxiosError, { name: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const response = await createOrganizationPost({
        name,
      });
      return response;
    },
    onSuccess: (organization) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully created.',
      });

      onSuccess?.(organization);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Creating organization error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useOrganizations = () => {
  return usePagination<TOrganization[]>({
    query: getAllOrganizations,
    queryKey: 'organizations',
  });
};

const useOrganizationById = (
  organizationId: string,
): {
  isLoading: boolean;
  isSuccess: boolean;
  data: TOrganization | undefined;
  isError: boolean;
  error: Error | null;
} => {
  const { isLoading, isSuccess, data, isError, error } = useQuery({
    queryKey: ['organization', organizationId],
    queryFn: async () => {
      const response = await organizationByIdGet({
        id: organizationId,
      });
      return response;
    },
  });

  return { isLoading, isSuccess, data, isError, error };
};

const useInviteOrganizationAdmin = (
  organizationId: string,
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<string, TCustomAxiosError, { firstName: string; lastName: string; email: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) => {
      const response = await inviteOrganizationAdminPost({
        organizationId,
        firstName,
        lastName,
        email,
      });
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Invitation successfully sent!',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Invite error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export { useCreateOrganization, useOrganizations, useOrganizationById, useInviteOrganizationAdmin };
