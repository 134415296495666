import {
  OrderedList,
  UnorderedList,
  useStyles,
} from "@chakra-ui/react";

interface Props {
  node: any;
  ordered: boolean;
}

const List = ({ ordered, ...props }: Props) => {
  const styles = useStyles();

  const ListComponent = ordered ? OrderedList : UnorderedList;

  return <ListComponent sx={styles.list} {...props} />;
};

export default List;
