import SliderLabelExtended from '@/components/atoms/Slider/SliderLabelExtended';
import { IDocumentComponentBase } from '@/types/Document.types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface Props extends IDocumentComponentBase {
  step?: number;
  reverseScoring?: boolean;
  min?: number;
  max?: number;
  defaultValue?: number;
  labelStart?: string;
  labelEnd?: string;
  sliderValues?: Array<number>;
}

const Slider = ({
  min = 1,
  max = 10,
  step = 1,
  defaultValue = 5,
  labelStart = 'Strongly Disagree',
  labelEnd = 'Absolutely Agree',
  sliderValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  reverseScoring = false,
  name,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <SliderLabelExtended
          sliderValues={sliderValues}
          min={min}
          max={max}
          step={step}
          value={value}
          setValue={(val) => {
            onChange(val);
          }}
          labelStart={labelStart}
          labelEnd={labelEnd}
          inverted={reverseScoring}
        />
      )}
    />
  );
};

export default Slider;
