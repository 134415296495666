'use client';

import { ReactNode, useCallback, useState } from 'react';

import Editor, { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';

import { options } from './constants';
import setupJSONCompletionItems from './languages';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useDocumentEditorStore } from '@/store/documentEditor';

type Props = {
  height?: string;
  width?: string;
  type?: string;
  defaultValue?: string;
  defaultLanguage?: string;
  defaultPath?: string;
  language?: string;
  path?: string;
  theme?: string;
  line?: number;
  loading?: ReactNode;
  saveViewState?: boolean;
  keepCurrentModel?: boolean;
  wrapperProps?: object;
  editorId?: string | number;
  handleChange: any;
  initValue?: string;
  value: string;
};

const theme: editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  colors: {
    'editor.background': '#91bfe31a',
  },
  rules: [],
};

const MonacoEditor = ({ editorId = 'json', language = 'json', handleChange, ...props }: Props) => {
  const [registeredLanguageConfigs, setRegisteredLanguageConfigs] = useState<string[]>([]);
  const { hasError, setHasError, editorMarkers, setEditorMarkers } = useDocumentEditorStore();

  const handleEditorDidMount = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
      // monaco.languages.json.jsonDefaults.setDiagnosticsOptions({ allowComments: false, validate: true })
      monaco.editor.defineTheme('previdence', theme);
      monaco.editor.setTheme('previdence');

      setTimeout(() => {
        editor.getAction('editor.action.formatDocument')?.run();
      }, 300);

      const customLanguageId = `custom-language-${editorId}`;

      if (!language && !registeredLanguageConfigs.includes(customLanguageId)) {
        setupJSONCompletionItems(monaco, customLanguageId);
        const newLanguages = registeredLanguageConfigs;
        newLanguages.push(customLanguageId);
        setRegisteredLanguageConfigs(newLanguages);
      }
    },
    [editorId, language, registeredLanguageConfigs, setRegisteredLanguageConfigs],
  );

  return (
    <VStack height={'90vh'} width={'100%'} borderWidth={'3px'} borderRadius={'6px'} borderColor={'primary.500'}>
      <Editor
        onChange={(value: string | undefined) => {
          handleChange(value);
        }}
        options={options}
        language={language || `custom-language-${editorId}`}
        defaultLanguage={language}
        theme="previdence"
        saveViewState={true}
        keepCurrentModel={false}
        // path={props.path}
        onMount={handleEditorDidMount}
        onValidate={(markers: any[]) => {
          const isValid = markers.length === 0;
          if (hasError && isValid) setHasError(false);
          if (!hasError && !isValid) setHasError(true);
          setEditorMarkers(markers);
        }}
        {...props}
      />
      <Box
        alignItems={'flex-start'}
        width={'100%'}
        bg="white"
        minHeight={'20%'}
        padding={'10px'}
        borderBottomRadius={'6px'}
      >
        <Text fontWeight={600} paddingBottom={'10px'}>
          Editor Logs
        </Text>
        {editorMarkers.map((marker, index) => (
          <VStack key={index.toString()} width={'100%'} minHeight={'100%'} alignItems={'flex-start'}>
            <Text color={'extra.red'}>
              {index}: {marker.message} {`[${marker.startColumn} - ${marker.endColumn}]`}
            </Text>
          </VStack>
        ))}
      </Box>
    </VStack>
  );
};

export default MonacoEditor;
