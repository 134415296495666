export const QUESTION_CONTAINER: string = `{
            "type": "QuestionContainer",
            "props": {},
            "children": [
                {
                    "type": "Text",
                    "children": "This is just a template",
                    "props": {
                        "variant": "loraTitle"
                    }
                },
                {
                    "id": "0e299534-67a5-4b8c-ae8a-3c5c5a26316e",
                    "type": "Slider",
                    "props": {
                        "subDomain": "template_domain",
                        "name": "satisfaciton",
                        "step": 1,
                        "min": 1,
                        "max": 10
                    }
                }
            ]
        }`;
