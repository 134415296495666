import { VStack } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

type Props = {}

const QuestionContainer = ({ children, ...props }: PropsWithChildren<Props>) => {
  return (
    <VStack
      className="embla__slide"
      marginTop={{
        sm: "20px",
        md: "40px",
      }}
      width={'100%'}
      {...props}
    >
      <VStack
        maxWidth={{
          sm: "820px",
          lg: "8200px",
          xl: "820px",
          "2xl": "1200px",
        }}
      >
        {children}
      </VStack>
    </VStack>
  )
}

export default QuestionContainer