import { Flex } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

const AppPageTemplate = ({ children }: PropsWithChildren) => {
  return (
    <Flex width={'100%'} direction={'column'} alignItems={'center'}>
      {children}
    </Flex>
  );
};

export default AppPageTemplate;
