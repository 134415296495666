import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_admin')({
  component: Outlet,
  beforeLoad: async ({ context: { auth } }) => {
    const isSuperAdmin = auth?.isSuperAdmin;
    if (!isSuperAdmin) {
      throw redirect({
        to: '',
      });
    }
  },
});
