import { TJsonDocumentBase } from '@/types/Document.types';
import { generateRandomString } from '@/utils/stringUtils';
import { Text } from '@chakra-ui/react';
import { isArray, isUndefined } from 'lodash';
import { createElement } from 'react';

const createElementFromJson = (
  json: TJsonDocumentBase,
  components: Record<string, React.ReactNode | any>,
  config: any,
): React.JSX.Element => {
  const { id, type, props = {}, children } = json;

  if (!type) {
    return (
      <Text fontWeight={'bold'} color={'red.500'}>
        JSON Document Error: No provided type for component to render.
      </Text>
    );
  }

  if (!components?.[type]) {
    return (
      <Text
        fontWeight={'bold'}
        color={'red.500'}
      >{`JSON Document Error: No component found for component type: " ${type} " .`}</Text>
    );
  }

  if (!isUndefined(children) && !isArray(children) && type !== 'Text' && type !== 'Markdown') {
    return (
      <Text
        fontWeight={'bold'}
        color={'red.500'}
      >{`JSON Document Error: Children of component type: " ${type} " , must be an array.`}</Text>
    );
  }

  const {
    // width,
    maxWidth,
    // height,
    maxHeight,
    minWidth,
    minHeight,
    display,
    ...rest
  } = props;

  return createElement(
    components[type],
    {
      ...rest,
      key: generateRandomString({ length: 6 }),
      id,
      _config: config,
    },
    isArray(children) && children.length > 0
      ? children?.map((child) => createElementFromJson(child, components, config))
      : children,
  );
};

export default createElementFromJson;
