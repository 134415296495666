import { useCreateElementFromJson } from '@/providers/DocumentProvider/DocumentProvider';
import formatObjectWithContextData from '@/providers/DocumentProvider/utils/formatObjectWithContextData';
import useTranslateData from '@/providers/DocumentProvider/utils/useTranslateData';
import { VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';

type Props = {
  content: string;
};

const EulaDocPreview = ({ content }: Props) => {
  const contentMap = { type: 'Markdown', children: content };

  const createElementFromJson = useCreateElementFromJson();

  const translatedData = useTranslateData(contentMap);

  const formattedData = useMemo(
    () =>
      formatObjectWithContextData({
        object: translatedData,
      }),
    [translatedData],
  );

  return (
    <VStack
      padding={'40px'}
      width={'100%'}
      bg={'white'}
      borderRadius={'10px'}
      boxShadow={'0px 10px 80px 0px primary.whiteOptionsShadow'}
      borderWidth={0}
    >
      {createElementFromJson(formattedData)}
    </VStack>
  );
};

export default EulaDocPreview;
