'use client';

import type React from 'react';
import { useCallback } from 'react';

import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDocumentEditorStore } from '@/store/documentEditor';

type Props = {
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
};

const Form = ({ children, title, subTitle, ...restProps }: Props) => {
  const { carouselItemIndex, setCarouselItemIndex } = useDocumentEditorStore();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex: carouselItemIndex,
    // watchDrag: () => { }, // disables dragging
  });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = () => {
    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      scrollToNextQuestion();

      return;
    }
  };

  const scrollToNextQuestion = useCallback(() => {
    const canGoNext = emblaApi?.canScrollNext();

    if (canGoNext) {
      emblaApi?.scrollNext();

      setCarouselItemIndex(carouselItemIndex + 1);

      return;
    }

    const vals = getValues();

    alert(JSON.stringify(vals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, getValues]);

  return (
    <FormProvider {...methods} {...restProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack alignItems={'center'}>
          <VStack
            maxWidth={{
              sm: '820px',
              lg: '8200px',
              xl: '820px',
              '2xl': '1200px',
            }}
          >
            <Text variant={'loraTitle'} textAlign={'center'}>
              {title}
            </Text>
            <Text variant={'finePrint'} textAlign={'center'} fontSize={'14px'} marginTop={'20px'}>
              {subTitle}
            </Text>
            {(title || subTitle) && (
              <HStack
                height={'1px'}
                maxWidth={'414px'}
                width={'100%'}
                bg="background.blueGray"
                marginY={'40px'}
                borderRadius={'full'}
              />
            )}
          </VStack>
          <Box ref={emblaRef}>
            <div
              className="embla__container"
              style={{
                // width: '100%',
                // alignItems: "center",
                // justifyContent: "center",
                minHeight: '50vh',
              }}
            >
              {children}
            </div>
          </Box>
        </VStack>
      </form>
      <VStack marginY={'60px'}>
        <Button variant={'continue'} type="submit" onClick={scrollToNextQuestion}>
          Continue
        </Button>
      </VStack>
    </FormProvider>
  );
};

export default Form;
