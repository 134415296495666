import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import useToast from '@/hooks/useToast';
import DrawerContainer from '@/components/molecules/DrawerContainer/DrawerContainer';
import CustomButton from '@/components/atoms/CustomButton';
import { QUESTION_CONTAINER } from '@/modules/assessment/Document/DocumentEditor/componentTemplates';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DocumentComponentsDrawer = ({ isOpen, onClose }: Props) => {
  const toast = useToast();

  const onComponentClick = (template: string, name: string) => {
    navigator.clipboard.writeText(template);

    toast({
      status: 'success',
      title: name,
      description: 'Component template copied to clipboard. Paste it inside code editor.',
    });

    onClose();
  };

  return (
    <DrawerContainer isOpen={isOpen} onClose={onClose} title="Component Templates">
      <Text>Click on each component to copy the JSON template to clipboard.</Text>
      <VStack width={'100%'} alignItems={'flex-start'} spacing={'20px'} marginTop={'40px'}>
        <CustomButton
          backgroundColor="secondary.500"
          labelColor="white"
          label="Question Container"
          onClick={() => {
            onComponentClick(QUESTION_CONTAINER, 'Question Container');
          }}
        />
      </VStack>
    </DrawerContainer>
  );
};

export default DocumentComponentsDrawer;
