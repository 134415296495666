import DocumentView from '@/modules/assessment/Document/DocumentView';
import { useDocumentEditorStore } from '@/store/documentEditor';
import { VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';

const EditingAssessmentPreview = () => {
  const { document } = useDocumentEditorStore();

  return (
    <VStack justify={'center'} height={'100vh'} overflow={'scroll'}>
      <DocumentView document={document} />
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/assessments/$assessmentId/preview')({
  component: EditingAssessmentPreview,
});
