import { TCustomAxiosError } from '@/services/api';
import {
  activateUserPost,
  deactivateUserPost,
  getAllActivatedUsers,
  getAllDeactivatedUsers,
} from '@/services/api/requests/user';
import { TUser, TUserFilter, TUserFilterOption } from '@/types/User.types';
import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import queryKeys from '@/constants/queryKeys';
import { usePaginatedSearch } from '../usePaginatedSearch';

const useActivatedUsers = ({ filterOption, filterText }: TUserFilter) => {
  return usePaginatedSearch<TUser[], TUserFilterOption, string>({
    query: getAllActivatedUsers,
    queryKey: queryKeys.users.activatedUsers,
    searchFilterType: filterOption ?? 'Email',
    searchFilterText: filterText,
  });
};

const useDeactivatedUsers = ({ filterOption, filterText }: TUserFilter) => {
  return usePaginatedSearch<TUser[], TUserFilterOption, string>({
    query: getAllDeactivatedUsers,
    queryKey: queryKeys.users.deactivatedUsers,
    searchFilterType: filterOption ?? 'Email',
    searchFilterText: filterText,
  });
};

const useUserActivation = (): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<AxiosResponse<TUser, any>, TCustomAxiosError, { userId: string }>;
} => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const response = await activateUserPost(userId);

      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'User successfully activated.',
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.deactivatedUsers],
        exact: false,
        stale: true,
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'User activation error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUserDeactivation = (): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<boolean, TCustomAxiosError, { userId: string; hasLastLogin: boolean }>;
} => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ userId, hasLastLogin }: { userId: string; hasLastLogin: boolean }) => {
      await deactivateUserPost(userId);

      return hasLastLogin;
    },
    onSuccess: (hasLastLogin: boolean) => {
      toast({
        status: 'success',
        title: 'Success',
        description: hasLastLogin
          ? 'User successfully deactivated.'
          : 'User successfully deleted and invitation invalidated.',
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.activatedUsers],
        exact: false,
        stale: true,
        type: 'all',
        refetchType: 'all',
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'User deactivation error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

export { useActivatedUsers, useDeactivatedUsers, useUserActivation, useUserDeactivation };
