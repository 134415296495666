type ColorObject = {
  [key: number]: string;
};

export const colors: ColorObject = {
  1: '#EC4D84',
  2: '#ef6f61',
  3: '#e09352',
  4: '#c8b161',
  5: '#a2cf8f',
  6: '#81d8a9',
  7: '#68d093',
  8: '#4fc77c',
  9: '#33be63',
  10: '#01B548',
  0: '#EC4D84',
  25: '#C995A3',
  50: '#A3E2C4',
  75: '#57CD8A',
  100: '#01B548',
};

export const colorsInverted: ColorObject = {
  100: '#EC4D84',
  75: '#C995A3',
  50: '#A3E2C4',
  25: '#57CD8A',
  0: '#01B548',
  10: '#EC4D84',
  9: '#ef6f61',
  8: '#e09352',
  7: '#c8b161',
  6: '#a2cf8f',
  5: '#81d8a9',
  4: '#68d093',
  3: '#4fc77c',
  2: '#33be63',
  1: '#01B548',
};
