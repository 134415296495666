import { TOrganization } from '@/types/Organization.types';
import { api, TCustomAxiosPaginationResponse } from '..';
import { ITEMS_PER_PAGE } from '@/theme/components/table';

const root = 'v1/organization';

const endpoints = {
  organization: `${root}`,
  inviteUsers: `${root}/invite/users`,
};

export const getAllOrganizations = async (page: number): Promise<TCustomAxiosPaginationResponse<TOrganization[]>> => {
  const { data } = await api.get(endpoints.organization, {
    params: {
      limit: ITEMS_PER_PAGE,
      page,
    },
  });

  return data;
};

export const organizationByIdGet = async ({ id }: { id: string }): Promise<TOrganization> => {
  const { data } = await api.get(`${endpoints.organization}/${id}`);

  const organization: TOrganization = data.data;

  return organization;
};

export const createOrganizationPost = async ({ name }: { name: string }): Promise<TOrganization> => {
  const { data } = await api.post(endpoints.organization, {
    name,
  });

  const organization = data.data;

  return organization;
};

export const updateOrganizationByIdPut = async (organizationId: string, orgInfo: TOrganization) => {
  const { data } = await api.put<TCustomAxiosPaginationResponse<TOrganization>>(
    `${endpoints.organization}/${organizationId}`,
    {
      ...orgInfo,
    },
  );
  const updatedOrganization = data.data;
  return updatedOrganization;
};

export const inviteUsersToOrganizationPost = async (
  users: { email: string; phone: string; firstName: string; lastName: string }[],
) => {
  const { data } = await api.post<TCustomAxiosPaginationResponse<unknown>>(endpoints.inviteUsers, {
    users,
  });

  return data.data;
};

export const inviteOrganizationAdminPost = async ({
  organizationId,
  firstName,
  lastName,
  email,
}: {
  organizationId: string;
  firstName: string;
  lastName: string;
  email: string;
}): Promise<string> => {
  const { data } = await api.post(`${endpoints.organization}/${organizationId}/invite-admin`, {
    firstName,
    lastName,
    email,
  });

  const message: string = data.data;

  return message;
};
