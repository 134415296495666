const setupJSONCompletionItems = (monaco: any, language: any) => {
  const jsonCompletionItems = (_: any) => {
    return [
      {
        label: 'array',
        kind: monaco.languages.CompletionItemKind.Class,
        insertText: '[]',
      },
      {
        label: 'object',
        kind: monaco.languages.CompletionItemKind.Class,
        insertText: '{\n\t$1\n}',
      },
      {
        label: 'string',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: '"value"',
      },
      {
        label: 'number',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: '${1:0}',
      },
      {
        label: 'null',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: 'null',
      },
      {
        label: 'undefined',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: 'undefiend',
      },
      {
        label: 'true',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: 'true',
      },
      {
        label: 'false',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: 'false',
      },
      {
        label: 'string (single-quoted)',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: "'${1:value}'",
      },
      {
        label: 'property',
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: '"${1:key}": ${2:value}',
      },
      {
        label: 'array element',
        kind: monaco.languages.CompletionItemKind.Value,
        insertText: '${1:value}',
      },
      {
        label: 'object property',
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: '"${1:key}": ${2:value}',
      },
      // Add more common properties and values as needed
    ];
  };
  monaco.languages.getCompletionProvider;
  monaco.languages.registerCompletionItemProvider(language, {
    provideCompletionItems: (model: any, position: any) => {
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };

      return {
        suggestions: jsonCompletionItems(range),
      };
    },
  });
};
export default setupJSONCompletionItems;
