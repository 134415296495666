import { useCreateElementFromJson } from '@/providers/DocumentProvider/DocumentProvider';
import formatObjectWithContextData from '@/providers/DocumentProvider/utils/formatObjectWithContextData';
import useTranslateData from '@/providers/DocumentProvider/utils/useTranslateData';
import React, { useMemo } from 'react';

type Props = {
  json: object;
};

const Document = ({ json }: Props): React.JSX.Element => {
  const createElementFromJson = useCreateElementFromJson();

  const translatedData = useTranslateData(json);

  const formattedData = useMemo(
    () =>
      formatObjectWithContextData({
        object: translatedData,
      }),
    [translatedData],
  );

  return <>{createElementFromJson(formattedData)}</>;
};

export default Document;
