"use client"

import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import Slider from './Slider';

type Props = {
  labelStart: string;
  labelEnd: string;
  value: number;
  setValue: (_: number) => void;
  marginTop?: string;
  defaultValue?: number;
  sliderValues?: Array<number>;
  step?: number;
  min?: number;
  max?: number;
  inverted?: boolean;
  isAnswered?: boolean;
}

const SliderLabelExtended = ({
  labelEnd,
  labelStart,
  value,
  setValue,
  marginTop,
  defaultValue,
  sliderValues,
  step,
  min,
  max,
  inverted,
  isAnswered,
  ...restProps
}: Props) => {
  return (
    <VStack
      paddingY={"21px"}
      paddingX={"33px"}
      backgroundColor={"extra.white"}
      maxWidth={"610px"}
      width={"100%"}
      borderRadius={"20px"}
      boxShadow={
        isAnswered ? "0px 8px 40px #00325E14" : "0px 8px 40px 0px #00417933"
      }
      marginTop={marginTop}
      {...restProps}
    >
      {(labelStart || labelEnd) && (
        <HStack justifyContent={"space-between"} width={"100%"}>
          <Text fontSize={"14px"} color="primary.darkBlue" lineHeight={"17px"}>
            {inverted ? labelEnd : labelStart}
          </Text>
          <Text fontSize={"14px"} color="primary.darkBlue">
            {inverted ? labelStart : labelEnd}
          </Text>
        </HStack>
      )}
      <Slider
        value={value}
        setValue={setValue}
        defaultValue={defaultValue}
        sliderValues={sliderValues}
        step={step}
        min={min}
        max={max}
        inverted={inverted}
      />
    </VStack>
  );
}

export default SliderLabelExtended